import { Button, ButtonCreate, DatePicker, InfoItemVertical, Input, InputFileSingle, Select } from "components";
import { Formik, useFormikContext } from "formik";
import { useModalConfirm, useUploads } from "hooks";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { dateConvert, errorFetchingSelectMapper, errorSubmitMapper, getFileLink } from "utilities";
import PekerjaApi from "../__PekerjaApi__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";

const DetailKepegawaian = () => {
  const date = dateConvert();
  const { values } = useFormikContext();

  return (
    <>
      <Row>
        <Col lg={4} className="mb-3">
          <Card>
            <Card.Header>
              <b>Identitas Kepegawaian</b>
            </Card.Header>
            <Card.Body>
              <InfoItemVertical label="NIP" text={values.nip} />
              <InfoItemVertical
                label="Tgl. Bergabung"
                text={values.tanggalBergabung ? date.getDetailFull(new Date(values.tanggalBergabung)) : ""}
              />
              <InfoItemVertical
                label="Tgl. Pengangkatan"
                text={values.tanggalPengangkatan ? date.getDetailFull(new Date(values.tanggalPengangkatan)) : ""}
              />
              <InfoItemVertical label="No. Pengangkatan" text={values.noPengangkatan} />
              <InfoItemVertical
                label="SK. Pengangkatan"
                text={
                  <a href={values.skPengangkatanFull} target="_blank" rel="noreferrer">
                    Lihat File
                  </a>
                }
              />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={8}>
          <Card>
            <Card.Header>
              <b>Detail Kepegawaian</b>
            </Card.Header>
            <Card.Body>
              <InfoItemVertical label="Jenis Pekerja" text={values.jenisPekerjaNama} />
              <InfoItemVertical label="Status Pekerja" text={values.statusPekerjaNama} />
              <InfoItemVertical label="Detail Status Pekerja" text={values.detailStatusPekerjaNama} />
              <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
              <InfoItemVertical label="Lokasi Kerja" text={values.lokasiKerjaNama} />
              <InfoItemVertical
                label="Hasil Psikotes"
                text={
                  <a href={values.hasilPsikotesFull} target="_blank" rel="noreferrer">
                    Lihat File
                  </a>
                }
              />
              <InfoItemVertical label="No. NPWP" text={values.noNpwp} />
              <InfoItemVertical label="No. BPJS Ketenagakerjaan" text={values.noBpjsKetenagakerjaan} />
              <InfoItemVertical label="No. BPJS Kesehatan" text={values.noBpjsKesehatan} />
              <InfoItemVertical label="No. Yadapen" text={values.noYadapen} />
              <InfoItemVertical
                label="Tahun Pensiun"
                text={values.tahunPensiun ? date.getYear(new Date(values.tahunPensiun)) : ""}
              />
              <InfoItemVertical
                label="Bulan Pensiun"
                text={values.bulanPensiun ? date.getDetailMonth(new Date(values.bulanPensiun)) : ""}
              />
              <InfoItemVertical
                label="Tgl. Keluar"
                text={values.tanggalKeluar ? date.getDetailFull(new Date(values.tanggalKeluar)) : ""}
              />

              <Card className="mb-2">
                <Card.Header>Kontak 1</Card.Header>
                <Card.Body>
                  <InfoItemVertical label="Nama Penanggung Jawab / Wali" text={values.namaWali} />
                  <InfoItemVertical label="Jenis Kontak Penanggung Jawab" text={values.jenisKontakNama} />
                  <InfoItemVertical label="Kontak Penanggung Jawab" text={values.kontakWali} />
                  <InfoItemVertical label="Hubungan Penanggung Jawab / Wali" text={values.hubunganWaliNama} />
                </Card.Body>
              </Card>
              {values?.namaWali1 && (
                <Card className="mb-2">
                  <Card.Header>Kontak 2</Card.Header>
                  <Card.Body>
                    <InfoItemVertical label="Nama Penanggung Jawab / Wali" text={values.namaWali1} />
                    <InfoItemVertical label="Jenis Kontak Penanggung Jawab" text={values.jenisKontakNama1} />
                    <InfoItemVertical label="Kontak Penanggung Jawab" text={values.kontakWali1} />
                    <InfoItemVertical label="Hubungan Penanggung Jawab / Wali" text={values.hubunganWaliNama1} />
                  </Card.Body>
                </Card>
              )}
              {values?.namaWali2 && (
                <Card className="mb-2">
                  <Card.Header>Kontak 3</Card.Header>
                  <Card.Body>
                    <InfoItemVertical label="Nama Penanggung Jawab / Wali" text={values.namaWali2} />
                    <InfoItemVertical label="Jenis Kontak Penanggung Jawab" text={values.jenisKontakNama2} />
                    <InfoItemVertical label="Kontak Penanggung Jawab" text={values.kontakWali2} />
                    <InfoItemVertical label="Hubungan Penanggung Jawab / Wali" text={values.hubunganWaliNama2} />
                  </Card.Body>
                </Card>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const FormKepegawaian = ({ tabProfileData }) => {
  const modalConfirm = useModalConfirm();
  const { values, errors, touched, handleChange, setFieldValue, setValues } = useFormikContext();
  const getJenisPekerja = useQuery(["jenisPekerja", "dropdown"], () => PekerjaApi.getJenisPekerja());
  const getStatusPekerja = useQuery(["statusPekerja", "dropdown"], () => PekerjaApi.getStatusPekerja());
  const getDetailStatusPekerja = useQuery(
    ["detailStatusPekerja", "dropdown", values?.statusPekerjaId],
    () => PekerjaApi.getDetailStatusPekerja(values?.statusPekerjaId),
    {
      enabled: Boolean(values?.statusPekerjaId)
    }
  );
  const getUnitOrganisasi = useQuery(["unitOrganisasi", "dropdown"], () => PekerjaApi.getUnitOrganisasi());
  const getLokasiKerja = useQuery(["lokasiKerja", "dropdown"], () => PekerjaApi.getLokasiKerja());
  const getJenisKontak = useQuery(["jenisKontak", "dropdown"], () => PekerjaApi.getJenisKontak());
  const getHubunganKeluarga = useQuery(["hubunganKeluarga", "dropdown"], () => PekerjaApi.getHubunganKeluarga());

  const uploadSkPengangkatan = useUploads(PekerjaApi.linkUploadKepegawaian, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({ ...values, skPengangkatan: link, skPengangkatanFull: getFileLink({ link, temporary: true }) });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload sk pengangkatan gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close,
        onCancel: modalConfirm.close
      })
  });

  const uploadPsikotes = useUploads(PekerjaApi.linkUploadKepegawaian, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({ ...values, hasilPsikotes: link, hasilPsikotesFull: getFileLink({ link, temporary: true }) });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload psikotes gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close,
        onCancel: modalConfirm.close
      })
  });

  return (
    <Row>
      {/* Identitas Kepegawaian */}
      <Col lg="4" className="mb-3">
        <Card>
          <Card.Header>
            <b>Identitas Kepegawaian</b>
          </Card.Header>
          <Card.Body>
            <>
              <Input
                label="NIK"
                name="nip"
                type="text"
                placeholder="Masukan nik"
                value={values?.nip}
                onChange={handleChange}
                error={Boolean(errors.nip && touched.nip)}
                errorText={Boolean(errors.nip && touched.nip) && errors.nip}
              />
              <DatePicker
                label="Tgl. Bergabung"
                placeholderText="Masukan tgl bergabung"
                selected={values.tanggalBergabung ? new Date(values.tanggalBergabung) : ""}
                onChange={(date) => setFieldValue("tanggalBergabung", date)}
                error={Boolean(errors.tanggalBergabung && touched.tanggalBergabung)}
                errorText={Boolean(errors.tanggalBergabung && touched.tanggalBergabung) && errors.tanggalBergabung}
              />
              <DatePicker
                label="Tgl. Pengangkatan"
                placeholderText="Masukan tgl pengangkatan"
                selected={values.tanggalPengangkatan ? new Date(values.tanggalPengangkatan) : ""}
                onChange={(date) => setFieldValue("tanggalPengangkatan", date)}
                error={Boolean(errors.tanggalPengangkatan && touched.tanggalPengangkatan)}
                errorText={
                  Boolean(errors.tanggalPengangkatan && touched.tanggalPengangkatan) && errors.tanggalPengangkatan
                }
              />
              <Input
                label="No. Pengangkatan"
                type="text"
                name="noPengangkatan"
                placeholder="Masukan no pengangkatan"
                value={values?.noPengangkatan}
                onChange={handleChange}
                error={Boolean(errors.noPengangkatan && touched.noPengangkatan)}
                errorText={Boolean(errors.noPengangkatan && touched.noPengangkatan) && errors.noPengangkatan}
              />
              <InputFileSingle
                label="Upload SK Pengangkatan"
                onClick={(e) => (uploadSkPengangkatan.isLoading ? e.preventDefault() : {})}
                disabled={uploadSkPengangkatan.isLoading}
                link={values.skPengangkatanFull}
                prefiew={Boolean(values.skPengangkatanFull)}
                onChange={(val) => {
                  let formData = new FormData();
                  formData.append("file", val.data);
                  uploadSkPengangkatan.mutateAsync(formData);
                }}
                error={Boolean(errors.skPengangkatan && touched.skPengangkatan)}
                errorText={Boolean(errors.skPengangkatan && touched.skPengangkatan) && errors.skPengangkatan}
                acceptFileType={["pdf"]}
              />
              {uploadSkPengangkatan.isLoading && (
                <ProgressBar
                  now={uploadSkPengangkatan.progress}
                  label={`Mengunggah file ${uploadSkPengangkatan.progress}%`}
                />
              )}
            </>
          </Card.Body>
        </Card>
      </Col>

      {/* Detail Kepegawaian */}
      <Col lg="8">
        <Card>
          <Card.Header>
            <b>Detail Kepegawaian</b>
          </Card.Header>
          <Card.Body>
            <>
              <Select
                label="Jenis Pekerja"
                placeholder="Masukan jenis pekerja"
                loading={getJenisPekerja.isLoading}
                options={getJenisPekerja?.data ?? []}
                defaultValue={getJenisPekerja?.data?.find((item) => item.value === values.jenisPekerjaId)}
                onChange={(val) => {
                  setValues({
                    ...values,
                    jenisPekerjaId: val.value,
                    jenisPekerjaNama: val.label
                  });
                }}
                error={Boolean(errors.jenisPekerjaId && touched.jenisPekerjaId)}
                errorText={Boolean(errors.jenisPekerjaId && touched.jenisPekerjaId) && errors.jenisPekerjaId}
                errorFetch={getJenisPekerja.isError}
                errorFetchText={errorFetchingSelectMapper(getJenisPekerja.error)}
              />
              <Select
                label="Status Pekerja"
                placeholder="Masukan status pekerja"
                loading={getStatusPekerja.isLoading}
                options={getStatusPekerja?.data ?? []}
                defaultValue={getStatusPekerja?.data?.find((item) => item.value === values.statusPekerjaId)}
                onChange={(val) => {
                  setValues({
                    ...values,
                    statusPekerjaId: val?.value,
                    statusPekerjaNama: val?.label
                  });
                }}
                error={Boolean(errors.statusPekerjaId && touched.statusPekerjaId)}
                errorText={Boolean(errors.statusPekerjaId && touched.statusPekerjaId) && errors.statusPekerjaId}
                errorFetch={getStatusPekerja.isError}
                errorFetchText={errorFetchingSelectMapper(getStatusPekerja.error)}
              />
              <Select
                label="Detail Status Pekerja"
                disable={!values?.statusPekerjaId}
                placeholder="Masukan detail status pekerja"
                loading={getDetailStatusPekerja.isLoading}
                options={getDetailStatusPekerja?.data ?? []}
                defaultValue={getDetailStatusPekerja?.data?.find((item) => item.value === values.detailStatusPekerjaId)}
                onChange={(val) => {
                  setValues({
                    ...values,
                    detailStatusPekerjaId: val?.value,
                    detailStatusPekerjaNama: val?.label
                  });
                }}
                error={Boolean(errors.detailStatusPekerjaId && touched.detailStatusPekerjaId)}
                errorText={
                  Boolean(errors.detailStatusPekerjaId && touched.detailStatusPekerjaId) && errors.detailStatusPekerjaId
                }
                errorFetch={getDetailStatusPekerja.isError}
                errorFetchText={errorFetchingSelectMapper(getDetailStatusPekerja.error)}
              />
              <Select
                label="Unit Organisasi"
                placeholder="Masukan unit organisasi"
                name="unitOrganisasiId"
                loading={getUnitOrganisasi.isLoading}
                options={getUnitOrganisasi?.data ?? []}
                defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                onChange={(val) => {
                  setValues({
                    ...values,
                    unitOrganisasiId: val?.value,
                    unitOrganisasiNama: val?.label
                  });
                }}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
                errorFetch={getUnitOrganisasi.isError}
                errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
              />
              <Select
                label="Lokasi Kerja"
                placeholder="Masukan lokasi kerja"
                name="lokasiKerjaId"
                loading={getLokasiKerja.isLoading}
                options={getLokasiKerja?.data ?? []}
                defaultValue={getLokasiKerja?.data?.find((item) => item.value === values.lokasiKerjaId)}
                onChange={(val) => {
                  setValues({
                    ...values,
                    lokasiKerjaId: val?.value,
                    lokasiKerjaNama: val?.label
                  });
                }}
                error={Boolean(errors.lokasiKerjaId && touched.lokasiKerjaId)}
                errorText={Boolean(errors.lokasiKerjaId && touched.lokasiKerjaId) && errors.lokasiKerjaId}
                errorFetch={getLokasiKerja.isError}
                errorFetchText={errorFetchingSelectMapper(getLokasiKerja.error)}
              />
              <InputFileSingle
                label="Hasil Psikotes"
                onClick={(e) => (uploadPsikotes.isLoading ? e.preventDefault() : {})}
                disabled={uploadPsikotes.isLoading}
                link={values.hasilPsikotesFull}
                preview={Boolean(values.hasilPsikotesFull)}
                onChange={(val) => {
                  let formData = new FormData();
                  formData.append("file", val.data);
                  uploadPsikotes.mutateAsync(formData);
                }}
                error={Boolean(errors.hasilPsikotes && touched.hasilPsikotes)}
                errorText={Boolean(errors.hasilPsikotes && touched.hasilPsikotes) && errors.hasilPsikotes}
                acceptFileType={["pdf"]}
              />
              {uploadPsikotes.isLoading && (
                <ProgressBar now={uploadPsikotes.progress} label={`Mengunggah file ${uploadPsikotes.progress}%`} />
              )}

              {/* DISINI SET NIK SEBAGAI NPWP */}
              <Input
                label="No. NPWP"
                type="text"
                name="noNpwp"
                placeholder="Masukan no npwp"
                value={values?.noNpwp}
                onChange={handleChange}
                error={Boolean(errors.noNpwp && touched.noNpwp)}
                errorText={Boolean(errors.noNpwp && touched.noNpwp) && errors.noNpwp}
              />
              <ButtonCreate
                tooltip
                tooltipText="Gunakan NIK sebagai NPWP"
                className="mb-2"
                text="Copy NIK sebagai NPWP"
                onClick={() => setValues({
                  ...values,
                  noNpwp: tabProfileData?.identitas.filter((obj) => {
                    return obj.jenisIdentitasId === 1 || obj.jenisIdentitasNama.toUpperCase() === "KTP"
                  })[0]?.noIdentitas
                })}
              />
              <Input
                label="No. BPJS Ketenagakerjaan"
                type="text"
                name="noBpjsKetenagakerjaan"
                placeholder="Masukan no bpjs ketenagakerjaan"
                value={values?.noBpjsKetenagakerjaan}
                onChange={handleChange}
                error={Boolean(errors.noBpjsKetenagakerjaan && touched.noBpjsKetenagakerjaan)}
                errorText={
                  Boolean(errors.noBpjsKetenagakerjaan && touched.noBpjsKetenagakerjaan) && errors.noBpjsKetenagakerjaan
                }
              />
              <Input
                label="No. BPJS Kesehatan"
                type="text"
                name="noBpjsKesehatan"
                placeholder="Masukan no bpjs kesehatan"
                value={values?.noBpjsKesehatan}
                onChange={handleChange}
                error={Boolean(errors.noBpjsKesehatan && touched.noBpjsKesehatan)}
                errorText={Boolean(errors.noBpjsKesehatan && touched.noBpjsKesehatan) && errors.noBpjsKesehatan}
              />
              <Input
                label="No. Yadapen"
                type="text"
                name="noYadapen"
                placeholder="Masukan no yadepen"
                value={values?.noYadapen}
                onChange={handleChange}
                error={Boolean(errors.noYadapen && touched.noYadapen)}
                errorText={Boolean(errors.noYadapen && touched.noYadapen) && errors.noYadapen}
              />
              <DatePicker
                label="Tahun Pensiun"
                name="tahunPensiun"
                placeholderText="Masukan tahun pensiun"
                selected={values?.tahunPensiun}
                onChange={(date) => setFieldValue("tahunPensiun", date)}
                showYearPicker
                dateFormat="yyyy"
                error={Boolean(errors.tahunPensiun && touched.tahunPensiun)}
                errorText={Boolean(errors.tahunPensiun && touched.tahunPensiun) && errors.tahunPensiun}
              />
              <DatePicker
                label="Bulan Pensiun"
                name="bulanPensiun"
                placeholderText="Masukan bulan pensiun"
                selected={values.bulanPensiun}
                onChange={(date) => setFieldValue("bulanPensiun", date)}
                dateFormat="MMMM"
                showMonthYearPicker
                showFullMonthYearPicker
                error={Boolean(errors.bulanPensiun && touched.bulanPensiun)}
                errorText={Boolean(errors.bulanPensiun && touched.bulanPensiun) && errors.bulanPensiun}
              />
              <DatePicker
                label="Tgl. Keluar"
                placeholderText="Masukan tanggal keluar"
                selected={values?.tanggalKeluar ? new Date(values.tanggalKeluar) : ""}
                onChange={(val) => setFieldValue("tanggalKeluar", val)}
                error={Boolean(errors.tanggalKeluar && touched.tanggalKeluar)}
                errorText={Boolean(errors.tanggalKeluar && touched.tanggalKeluar) && errors.tanggalKeluar}
              />
              <Card className="mb-2">
                <Card.Header>Kontak 1</Card.Header>
                <Card.Body>
                  <Input
                    label="Nama Penanggung Jawab / Wali"
                    type="text"
                    name="namaWali"
                    placeholder="Masukan nama penanggung jawab / wali"
                    value={values?.namaWali}
                    onChange={handleChange}
                    error={Boolean(errors.namaWali && touched.namaWali)}
                    errorText={Boolean(errors.namaWali && touched.namaWali) && errors.namaWali}
                  />
                  <Row>
                    <Col lg="4">
                      <Select
                        label="Kontak Penanggung Jawab / Wali"
                        placeholder="Masukan Penanggung Jawab / Wali"
                        name="jenisKontakId"
                        loading={getJenisKontak.isLoading}
                        options={getJenisKontak?.data ?? []}
                        defaultValue={getJenisKontak?.data?.find((item) => item.value === values.jenisKontakId)}
                        onChange={(val) => {
                          setValues({
                            ...values,
                            jenisKontakId: val?.value,
                            jenisKontakNama: val?.label
                          });
                        }}
                        error={Boolean(errors.jenisKontakId && touched.jenisKontakId)}
                        errorText={Boolean(errors.jenisKontakId && touched.jenisKontakId) && errors.jenisKontakId}
                        errorFetch={getJenisKontak.isError}
                        errorFetchText={errorFetchingSelectMapper(getJenisKontak.error)}
                      />
                    </Col>
                    <Col lg="8">
                      <Input
                        label=""
                        type="text"
                        name="kontakWali"
                        placeholder="Masukan kontak wali"
                        value={values?.kontakWali}
                        onChange={handleChange}
                        className={"mt-4"}
                        error={Boolean(errors.kontakWali && touched.kontakWali)}
                      />
                    </Col>
                  </Row>
                  <Select
                    label="Hubungan Penanggung Jawab / Wali"
                    placeholder="Masukan hubungan penanggung jawab / wali"
                    name="hubunganWaliId"
                    loading={getHubunganKeluarga.isLoading}
                    options={getHubunganKeluarga?.data ?? []}
                    defaultValue={getHubunganKeluarga?.data?.find((item) => item.value === values.hubunganWaliId)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        hubunganWaliId: val?.value,
                        hubunganKeluargaNama: val?.label
                      });
                    }}
                    error={Boolean(errors.hubunganWaliId && touched.hubunganWaliId)}
                    errorText={Boolean(errors.hubunganWaliId && touched.hubunganWaliId) && errors.hubunganWaliId}
                    errorFetch={getHubunganKeluarga.isError}
                    errorFetchText={errorFetchingSelectMapper(getHubunganKeluarga.error)}
                  />
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Header>Kontak 2</Card.Header>
                <Card.Body>
                  <Input
                    label="Nama Penanggung Jawab / Wali"
                    type="text"
                    name="namaWali1"
                    placeholder="Masukan nama penanggung jawab / wali"
                    value={values?.namaWali1}
                    onChange={handleChange}
                    error={Boolean(errors.namaWali1 && touched.namaWali1)}
                    errorText={Boolean(errors.namaWali1 && touched.namaWali1) && errors.namaWali1}
                  />
                  <Row>
                    <Col lg="4">
                      <Select
                        label="Kontak Penanggung Jawab / Wali"
                        placeholder="Masukan Penanggung Jawab / Wali"
                        name="jenisKontakId1"
                        loading={getJenisKontak.isLoading}
                        options={getJenisKontak?.data ?? []}
                        defaultValue={getJenisKontak?.data?.find((item) => item.value === values.jenisKontakId1)}
                        onChange={(val) => {
                          setValues({
                            ...values,
                            jenisKontakId1: val?.value,
                            jenisKontakNama1: val?.label
                          });
                        }}
                        error={Boolean(errors.jenisKontakId1 && touched.jenisKontakId1)}
                        errorText={Boolean(errors.jenisKontakId1 && touched.jenisKontakId1) && errors.jenisKontakId1}
                        errorFetch={getJenisKontak.isError}
                        errorFetchText={errorFetchingSelectMapper(getJenisKontak.error)}
                      />
                    </Col>
                    <Col lg="8">
                      <Input
                        label=""
                        type="text"
                        name="kontakWali1"
                        placeholder="Masukan kontak wali"
                        value={values?.kontakWali1}
                        onChange={handleChange}
                        className={"mt-4"}
                        error={Boolean(errors.kontakWali1 && touched.kontakWali1)}
                      />
                    </Col>
                  </Row>
                  <Select
                    label="Hubungan Penanggung Jawab / Wali"
                    placeholder="Masukan hubungan penanggung jawab / wali"
                    name="hubunganWaliId"
                    loading={getHubunganKeluarga.isLoading}
                    options={getHubunganKeluarga?.data ?? []}
                    defaultValue={getHubunganKeluarga?.data?.find((item) => item.value === values.hubunganWaliId1)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        hubunganWaliId1: val?.value,
                        hubunganKeluargaNama1: val?.label
                      });
                    }}
                    error={Boolean(errors.hubunganWaliId1 && touched.hubunganWaliId1)}
                    errorText={Boolean(errors.hubunganWaliId1 && touched.hubunganWaliId1) && errors.hubunganWaliId1}
                    errorFetch={getHubunganKeluarga.isError}
                    errorFetchText={errorFetchingSelectMapper(getHubunganKeluarga.error)}
                  />
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Header>Kontak 3</Card.Header>
                <Card.Body>
                  <Input
                    label="Nama Penanggung Jawab / Wali"
                    type="text"
                    name="namaWali2"
                    placeholder="Masukan nama penanggung jawab / wali"
                    value={values?.namaWali2}
                    onChange={handleChange}
                    error={Boolean(errors.namaWali2 && touched.namaWali2)}
                    errorText={Boolean(errors.namaWali2 && touched.namaWali2) && errors.namaWali2}
                  />
                  <Row>
                    <Col lg="4">
                      <Select
                        label="Kontak Penanggung Jawab / Wali"
                        placeholder="Masukan Penanggung Jawab / Wali"
                        name="jenisKontakId2"
                        loading={getJenisKontak.isLoading}
                        options={getJenisKontak?.data ?? []}
                        defaultValue={getJenisKontak?.data?.find((item) => item.value === values.jenisKontakId2)}
                        onChange={(val) => {
                          setValues({
                            ...values,
                            jenisKontakId2: val?.value,
                            jenisKontakNama2: val?.label
                          });
                        }}
                        error={Boolean(errors.jenisKontakId2 && touched.jenisKontakId2)}
                        errorText={Boolean(errors.jenisKontakId2 && touched.jenisKontakId2) && errors.jenisKontakId2}
                        errorFetch={getJenisKontak.isError}
                        errorFetchText={errorFetchingSelectMapper(getJenisKontak.error)}
                      />
                    </Col>
                    <Col lg="8">
                      <Input
                        label=""
                        type="text"
                        name="kontakWali2"
                        placeholder="Masukan kontak wali"
                        value={values?.kontakWali2}
                        onChange={handleChange}
                        className={"mt-4"}
                        error={Boolean(errors.kontakWali2 && touched.kontakWali2)}
                      />
                    </Col>
                  </Row>
                  <Select
                    label="Hubungan Penanggung Jawab / Wali"
                    placeholder="Masukan hubungan penanggung jawab / wali"
                    name="hubunganWaliId2"
                    loading={getHubunganKeluarga.isLoading}
                    options={getHubunganKeluarga?.data ?? []}
                    defaultValue={getHubunganKeluarga?.data?.find((item) => item.value === values.hubunganWaliId2)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        hubunganWaliId2: val?.value,
                        hubunganKeluargaNama2: val?.label
                      });
                    }}
                    error={Boolean(errors.hubunganWaliId2 && touched.hubunganWaliId2)}
                    errorText={Boolean(errors.hubunganWaliId2 && touched.hubunganWaliId2) && errors.hubunganWaliId2}
                    errorFetch={getHubunganKeluarga.isError}
                    errorFetchText={errorFetchingSelectMapper(getHubunganKeluarga.error)}
                  />
                </Card.Body>
              </Card>
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export const TabKepegawaian = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateKepegawaian = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ size: "md", typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ size: "md", typeInfo: "update", ...errorSubmitMapper(err) })
  });
  const updateKepegawaianSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ size: "md", typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ size: "md", typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      size: "lg",
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateKepegawaianSelf.mutate(formSubmitMapper.kepegawaian(values));
        } else {
          updateKepegawaian.mutate(formSubmitMapper.kepegawaian(values));
        }
      },
      component: (
        <Formik initialValues={values}>
          <DetailKepegawaian />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues.kepegawaian(parentFormik.values)}
      validationSchema={formValidationSchema.kepegawaian}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            {action === "READ" ? (
              <DetailKepegawaian />
            ) : (
              <>
                <small className="text-danger mb-2">
                  <b>FORM DATA KEPEGAWAIAN WAJIB DIISI</b>
                </small>
                <FormKepegawaian tabProfileData={formInitialValues.profile(parentFormik.values)} />
              </>
            )}
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
