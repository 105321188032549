export const formSubmitValueMapper = {
    objWithArray: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_setup: data?.tglSetup,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        denda_total: data?.totalUangDenda,
        denda_permenit: data?.uangDendaPermenit,
        detail: data?.detail?.map((val) => ({
            tgl_presensi: val?.tglPresensi,
            jam: val?.jam,
            jam_in: val?.jamIn,
            menit_terlambat: val?.jumlahTerlambat,
            no_surat_ijin: val?.noSurat,
            jenis_ijin: val?.jenisIjinNama,
            denda_harian: val.nominal
        }))
    }),
    object: (data) => ({
        periode_tahun: data?.periodeGajianId,
        // tgl_setup: data?.tglSetup,
        // tgl_bulan_awal: data?.tglAwal,
        // tgl_bulan_akhir: data?.tglAkhir,
        // pekerja_id: data?.pekerjaId,
        // master_unit_organisasi_id: data?.unitOrganisasiId,
        denda_total: data?.nominal
    }),
    quickPostData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        tgl_setup: data?.tglSetup
    })
};
