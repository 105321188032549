import {
  TunjanganUangLemburKubikasiList,
  TunjanganUangLemburKubikasiCreate,
  TunjanganUangLemburKubikasiDetail,
  TunjanganUangLemburKubikasiUpdate,
  TunjanganUangLemburKubikasiPostList
} from "pages/Payroll";

export const TunjanganUangLemburKubikasi = {
  component: <TunjanganUangLemburKubikasiList />,
  link: "tunjangan-uang-lembur-kubikasi",
  name: "Tunjangan Uang Lembur Kubikasi",
  access: "PEKERJA_KUBIKASI_BULANAN",
  child: [
    {
      name: "List Pekerja",
      link: "/post-list",
      component: <TunjanganUangLemburKubikasiPostList />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <TunjanganUangLemburKubikasiCreate />
    },
    {
      name: "Detail",
      link: "/detail/:id",
      component: <TunjanganUangLemburKubikasiDetail />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <TunjanganUangLemburKubikasiUpdate />
    }
  ]
};
