import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import {
  TableList,
  InfoItemVertical,
  numberConvert,
  Select,
  DatePicker,
  ButtonCreate,
  InputCurrency,
  Input,
  dateConvert
} from "components";
import { Row, Col, Card, ButtonGroup } from "react-bootstrap";
import TunjanganRitaseiApi from "../__TunjanganRitaseApi__";
import { checkMonth } from "../__TunjanganRitaseUtils__";
import { useAccessRights } from "hooks";
import { useNavigate } from "react-router-dom";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  // const date = dateConvert();

  const defaultSearch = {
    periodeGajianId: values?.periodeGajianId,
    periodePerencanaan: values?.periodePerencanaan,
    bulan: values?.bulan,
    unitOrganisasiId: values?.unitOrganisasiId,
    unitOrganisasiNama: values?.unitOrganisasiNama,
    pekerjaId: values?.pekerjaId,
    pekerjaNama: values?.pekerjaNama,
    tglAwal: values?.tglAwal,
    tglAkhir: values?.tglAkhir,
    tglSetup: values?.tglSetup
  };

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => TunjanganRitaseiApi.getUnitOrganisasi());
  const getPekerja = useQuery(["pekerja", "dropdown", values?.unitOrganisasiId], () =>
    TunjanganRitaseiApi.getPekerja({
      master_unit_organisasi_id: values?.unitOrganisasiId
    })
  );
  const getBulan = useQuery(["bulan", "dropdown"], () => TunjanganRitaseiApi.getBulan());

  return (
    <>
      <Input disabled label="Periode Penggajian" value={values?.periodePerencanaan} />
      {/* <Select
        label="Unit Organisasi"
        options={getUnitOrganisasi?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={
          values?.unitOrganisasiId
            ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
            : null
        }
        onChange={(val) => {
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label
          });
        }}
        loading={getUnitOrganisasi?.isFetching}
        errorFetch={getUnitOrganisasi.isError}
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
      />
      <Select
        label="Pekerja"
        options={getPekerja?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.pekerjaId ? getPekerja?.data?.find((item) => item.value === values?.pekerjaId) : null}
        onChange={(val) => {
          setValues({
            ...values,
            pekerjaId: val.value,
            pekerjaNama: val.label
          });
        }}
        loading={getPekerja?.isFetching}
        errorFetch={getPekerja.isError}
        error={Boolean(errors.pekerjaId && touched.pekerjaId)}
        errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
      /> */}
      <Select
        label="Bulan Penggajian"
        disable={Boolean(!values?.periodeGajianId)}
        options={getBulan?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
        onChange={(val) => {
          const tglNewStart = val.value <= 1 ?
            `${values?.periodePerencanaan - 1}-12-26`
            : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

          const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

          setValues({
            ...values,
            bulan: val.value,
            bulanNama: val.label,
            tglAwal: tglNewStart,
            tglAkhir: tglNewEnd
          });
        }}
        loading={getBulan?.isFetching}
        errorFetch={getBulan.isError}
        error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
        errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
      />
      <div>
        <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
              disable={Boolean(!values?.bulan)}
              onChange={(val) =>
                setValues({
                  ...values,
                  tglAwal: val,
                  bulan: new Date(val).getMonth() + 1,
                  tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30)),
                  validateDate: checkMonth(val, values?.tglSetup)
                })
              }
              error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
              errorText={
                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
              }
            />
          </div>
          <small>s/d</small>
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
              disable={Boolean(!values?.bulan)}
              onChange={(val) =>
                setValues({
                  ...values,
                  tglAkhir: val
                })
              }
              error={Boolean(errors.tglAkhir && touched.tglAkhir)}
              errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
            />
          </div>
        </div>
      </div>

      <DatePicker
        disabled
        label="Tanggal Setup"
        placeholderText="dd-mm-yyyy"
        selected={values?.tglSetup}
        onChange={(val) =>
          setValues({
            ...values,
            tglSetup: val
          })
        }
        error={Boolean(errors.tglSetup && touched.tglSetup)}
        errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
      />

      <div className="d-flex justify-content-end">
        <div>
          {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
          <ButtonCreate
            disabled={Boolean(
              !values?.periodeGajianId || !values?.bulan
            )}
            text="CARI"
            className="mb-3"
            tooltip={false}
            onClick={() =>
              onSearchButtonClick({
                active: true,
                data: defaultSearch
              })
            }
          />
        </div>
      </div>
    </>
  );
};

const TabelDataPekerja = ({ action }) => {
  const { values, errors, touched } = useFormikContext();
  const number = numberConvert();
  const date = dateConvert();

  // const hourToMinute = () => 4 * 60;

  // const setNewValues = ({ index, propname, val }) => {
  //   const detailTemp = [...values?.detail];
  //   const updatedDetail = detailTemp[index];
  //   updatedDetail[propname] = val;

  //   setValues({ ...values, detail: detailTemp });
  // };

  const getError = (index, propname) => {
    return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
  };

  const getErrorText = (index, propname) => {
    return (
      Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
      errors?.detail?.[index]?.[propname]
    );
  };

  const arrayTotalUangLembur = values?.detail?.reduce((prev, curr) => {
    let hasil = 0;
    hasil = hasil + curr?.upah;
    return [...prev, hasil];
  }, []);

  const calculationFunction = (arr) => {
    let sum = 0;
    arr.map((e) => (sum += e));
    return sum;
  };

  return (
    <>
      <Row className="mb-4">
        <Col md={6}>
          <InfoItemVertical label="Periode Penggajian" text={values.periodePerencanaan} />
          <InfoItemVertical
            label="Bulan Penggajian"
            text={
              (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-") +
              " s/d " +
              (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
            }
          />
          <InfoItemVertical label="NIK" text={values.nik} />
        </Col>
        <Col md={6}>
          <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
          <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
          <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
        </Col>
        {/* <Col md={6}>
          <InputCurrency
            id="totalUpah"
            placeholder="Masukkan nominal uang ritase"
            disabled={Boolean(values?.detail.length === 0 || !values?.pekerjaId || action === "READ")}
            label="Uang Ritase"
            value={number.getWithComa(values?.totalUpah, { minimumFractionDigits: 2 })}
            onChange={(val) => {
              if (values?.detail.length !== 0) {
                const arr = values?.detail;
                const newArr = arr.reduce((accumulator, obj) => {
                  const valNominal = obj.jamKerjaMenit >= hourToMinute() ? val : 0;
                  return [
                    ...accumulator,
                    {
                      nominal: valNominal,
                      tglPresensi: obj.tglPresensi
                    }
                  ];
                }, []);

                setValues({
                  ...values,
                  totalUpah: val,
                  detail: newArr
                });
              }
            }}
            error={Boolean(errors.totalUpah && touched.totalUpah)}
            errorText={Boolean(errors.totalUpah && touched.totalUpah) && errors.totalUpah}
          />
        </Col> */}
      </Row>

      <TableList
        maxHeight={550}
        tableHeader={[
          { text: "Tgl. Pengiriman" },
          { text: "Urutan Ritase" },
          { text: "No. Doket" },
          { text: "Customer" },
          { text: "Proyek" },
          { text: "Jenis Ritase" },
          { text: "Jarak (KM)" },

          { text: "Upah" }
        ]}
        tableBody={[
          {
            customField: (val) => (val?.tglPengiriman ? date.getSlashDMY(new Date(val?.tglPengiriman)) : "-")
          },

          {
            customField: (val) =>
              val?.urutanRitase ? number.getWithComa(val?.urutanRitase, { minimumFractionDigits: 0 }) : "-"
          },
          {
            customField: (val) => (val?.noDoket ? number.getWithComa(val?.noDoket, { minimumFractionDigits: 0 }) : "-")
          },
          {
            field: "customer"
          },
          {
            field: "proyek"
          },
          {
            field: "jenisRitaseNama"
          },
          {
            customField: (val) => (val?.jarak ? number.getWithComa(val?.jarak, { minimumFractionDigits: 0 }) : "-")
          },

          {
            props: { textRight: true },
            customField: (val, index) =>
              action === "READ" ? (
                "Rp" + (val?.upah ? number.getWithComa(val?.upah, { minimumFractionDigits: 2 }) : "0")
              ) : (
                <InputCurrency
                  disabled
                  name="upah"
                  className="text-end"
                  value={"Rp" + number.getWithComa(val?.upah, { minimumFractionDigits: 2 })}
                  error={getError(index, "upah")}
                  errorText={getErrorText(index, "upah")}
                />
              )
          }
        ]}
        data={values?.detail}
        tableFooter={[
          {
            text: "Total Tunjangan Uang Ritase",
            props: { colSpan: 8 }
          },
          {
            props: { textRight: true },
            text: calculationFunction(arrayTotalUangLembur)
              ? "Rp" + number.getWithComa(calculationFunction(arrayTotalUangLembur), { minimumFractionDigits: 2 })
              : "Rp" + 0
          }
        ]}
      />
    </>
  );
};

const TabelPostList = ({ action, setCreateViewState, onPostButtonClickHandler, filterData }) => {
  const { values } = useFormikContext();
  const access = useAccessRights("PEKERJA_LEMBUR_PERJAM_BULANAN");
  const number = numberConvert();
  const navigate = useNavigate();

  return (
    <>
      <TableList
        maxHeight={550}
        tableHeader={[
          { text: "NIK" },
          { text: "Pekerja" },
          { text: "Unit Organisasi" },
          { text: "Jabatan" },
          { text: "Upah" },
          { text: "Aksi" }
        ]}
        tableBody={[
          {
            field: "nik"
          },
          {
            field: "pekerjaNama"
          },
          {
            field: "unitOrganisasiNama"
          },
          {
            field: "jabatanNama"
          },
          {
            props: { textRight: true },
            customField: (val) => val?.upah ? `Rp${number.getWithComa(val?.upah, { minimumFractionDigits: 2 })}` : "Rp0,00"
          },
          {
            props: {
              width: 50,
              fixed: true,
              textCenter: true
            },
            customField: (val) => (
              <ButtonGroup size="sm">
                {access.canCreate() && (
                  <ButtonCreate tooltip={false} disabled={val?.hasPosted === true ? true : false} text="POST" onClick={() => {
                    navigate("/payroll/transaksi/tunjangan-ritase/tambah",
                      {
                        state: {
                          periodeGajianId: val?.periodeGajianId,
                          unitOrganisasiId: val?.unitOrganisasiId,
                          unitOrganisasiNama: val?.unitOrganisasiNama,
                          pekerjaId: val?.pekerjaId,
                          pekerjaNama: val?.pekerjaNama,
                          jabatanNama: val?.jabatanNama,
                          nik: val?.nik,
                          tglAwal: val?.tglAwal,
                          tglAkhir: val?.tglAkhir
                        }
                      })
                    // setCreateViewState("CREATE");
                    // onPostButtonClickHandler({
                    //   active: true,
                    //   data: {
                    //     ...filterData,
                    //     unitOrganisasiId: val?.unitOrganisasiId,
                    //     unitOrganisasiNama: val?.unitOrganisasiNama,
                    //     pekerjaId: val?.pekerjaId,
                    //     pekerjaNama: val?.pekerjaNama,
                    //     jabatanNama: val?.jabatanNama,
                    //     nik: val?.nik
                    //   }
                    // })
                  }} />
                )}
              </ButtonGroup>
            )
          }
        ]}
        data={values?.detail}
      />
    </>
  )
}

export const Content = ({ action, onSearchButtonClick, onResetClickButton, setCreateViewState, onPostButtonClickHandler, filterData }) => {
  return (
    <>
      <Row>
        {action === "POST" ? (
          <>
            <Col md={4} className="mb-4">
              <Card style={{ height: "100%" }}>
                <Card.Header>
                  <b>Setup Data</b>
                </Card.Header>
                <Card.Body>
                  <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <Card style={{ height: "100%" }}>
                <Card.Header>
                  <b>Data Pekerja</b>
                </Card.Header>
                <Card.Body>
                  <TabelPostList action={action} setCreateViewState={setCreateViewState} onPostButtonClickHandler={onSearchButtonClick} filterData={filterData} />
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <Col md={12}>
            <Card style={{ height: "100%" }}>
              <Card.Header>
                <b>Data Pekerja</b>
              </Card.Header>
              <Card.Body>
                <TabelDataPekerja action={action} />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};
