import { Services } from "services";

class BaganStrukturApi {
    async getList(params) {
        const fetch = await Services.get("/transaksi/bagan-unit", { params });
        return fetch?.data?.data;
    }

}

export default new BaganStrukturApi();
