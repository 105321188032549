export const formSubmitValueMapper = {
    detailData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_setup: data?.tglSetup,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        lembur_perjam: data?.uangLembur,
        total_uang_lembur: data?.totalUangLembur,
        total_uang_kompensasi: data?.totalUangKompensasi,
        detail: data?.detail?.map((val) => ({
            tgl_presensi: val?.tglPresensi,
            jam_in: val?.jamIn,
            jam_out: val?.jamOut,
            jam_lembur: val?.jamLembur,
            uang_lembur: val?.nominal,
            nomor: val?.nomor,
            uang_kompensasi: val?.nominalKompensasi,
            jam_lembur_surat: val?.jamLemburDitugaskan
        }))
    }),

    modalData: (data) => ({
        pekerja_lembur_perjam_bulanan_id: data?.id,
        komponen_payroll: data?.komponenPayroll,
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        tgl_setup: data?.tglSetup,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        lembur_perjam: data?.lemburPerjam,
        total_uang_lembur: data?.totalUangLembur,
    }),

    quickPostData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
    })
};
