export const formInitialValues = (data = {}, filter) => ({
  dataFormSetup: {
    periodeGajianId: data?.data?.info?.periode_tahun ?? filter?.periodeGajianId,
    periodePerencanaan: data?.data?.info?.periode_tahun ?? new Date().getFullYear(),
    unitOrganisasiId: filter?.unitOrganisasiId ?? "",
    unitOrganisasiNama: filter?.unitOrganisasiNama ?? "",
    bulan: filter?.bulan,
    tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
    tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
    tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
    nominal: false,
    detail:
      data?.data?.map((val) => ({
        checked: false,
        periodeGajianId: val?.periode_tahun ?? "",
        tglSetup: val?.tgl_setup ?? filter?.tglSetup,
        tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
        tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
        nik: val?.nip ?? "",
        pekerjaId: val?.pekerja_id ?? "",
        pekerjaNama: val?.pekerja_nama ?? "",
        unitOrganisasiId: val?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
        jabatanId: val?.master_jabatan_id ?? "",
        jabatanNama: val?.master_jabatan_nama ?? "",
        nominal: val?.final_tunjangan_tetap ?? "",
        hasPosted: val?.has_posted
      })).sort((a, b) => { return (a.hasPosted === b.hasPosted) ? 0 : a.hasPosted ? 1 : -1 }) ?? []
  },

  detailData: {
    id: data?.pekerja_tunjangan_tetap_bulanan_id ?? "",
    komponenPayroll: data?.komponen_payroll ?? "",
    periodeGajianId: data?.periode_tahun ?? "",
    periodePerencanaan: data?.periode_tahun ?? "",
    tglSetup: data?.tgl_setup ?? "",
    nik: data?.nip ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",
    nominal: data?.final_tunjangan_tetap ?? "",
    status: data?.status ?? ""
  }
});
