export const formSubmitValueMapper = {
  objWithArray: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    uang_makan: data?.uangMakan,
    detail: data?.detail?.map(
      (val) => ({
        tgl_presensi: val?.tglPresensi,
        jam_kerja: val?.jamKerjaMenit,
        uang_makan: val?.nominal
      }),
      []
    )
  }),
  object: (data) => ({
    periode_tahun: data?.periodeGajianId,
    // tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    total_uang_makan: data?.nominal
  }),
  quickPostData: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    tgl_setup: data?.tglSetup
  })
};
