export const formSubmitMapper = (data) => ({
  array: data?.detail?.filter((obj) => obj.hasPosted === false).map((val) => ({
    pekerja_upah_pokok_id: val?.pekerjaUpahPokokId,
    master_unit_organisasi_id: val?.unitOrganisasiId,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    periode_tahun: val?.periodeGajianId,
    tgl_setup: val?.tglSetup,
    pekerja_id: val?.pekerjaId,
    upah_pokok: val?.upahPokok,
    denda_keterlambatan: val?.dendaKeterlambatan,
    final_upah_pokok: val?.finalUpahPokok,
    has_post: val.hasPosted
  })),

  object: {
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tlAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    final_upah_pokok: data?.finalUpahPokok
  }
});
