export const formInitialValues = {
    dataFormSetup: (data = {}, filter) => ({
        periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId,
        periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
        pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
        pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
        nik: data?.nip,
        bulan: filter?.bulan,
        tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
        tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
        tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
        unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
        uangDendaPermenit: data?.denda_permenit,
        totalUangDenda: data?.denda_total,
        nominal: false,
        detail:
            data?.detail?.map((val) => ({
                tglPresensi: val?.tgl_presensi,
                jam: val?.jam,
                jamIn: val?.jam_in,
                jumlahTerlambat: val?.menit_terlambat,
                noSurat: val?.no_surat_ijin,
                jenisIjinId: val?.master_jenis_ijin_id,
                jenisIjinNama: val?.jenis_ijin,
                nominal: parseFloat(val?.denda_harian) ?? 0
            })) ?? []
    }),

    detailData: (data = {}) => ({
        id: data?.pekerja_denda_bulanan_id ?? "",
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun ?? "",
        periodePerencanaan: data?.periode_tahun ?? "",
        tglSetup: data?.tgl_setup ?? "",
        tglAwal: data?.tgl_bulan_awal,
        tglAkhir: data?.tgl_bulan_akhir,
        nik: data?.nip ?? "",
        pekerjaId: data?.pekerja_id ?? "",
        pekerjaNama: data?.pekerja_nama ?? "",
        unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
        jabatanId: data?.master_jabatan_id ?? "",
        jabatanNama: data?.master_jabatan_nama ?? "",
        dendaPermenit: data?.denda_permenit ?? "",
        uangDendaPermenit: false,
        nominal: data?.denda_total ?? ""
    }),

    formData: (data, filter) => ({
        periodeGajianId: filter?.periodeGajianId,
        periodePerencanaan: new Date().getFullYear(),
        bulan: filter?.bulan,
        tglAwal: filter?.tglAwal ?? data?.tgl_bulan_awal ?? "",
        tglAkhir: filter?.tglAkhir ?? data?.tgl_bulan_akhir ?? "",
        tglSetup: filter?.tglSetup ?? data?.tgl_setup ?? new Date(),

        detail:
            data?.list_presensi?.map((val) => ({
                nik: val?.nip,
                pekerjaId: val?.pekerja_id,
                pekerjaNama: val?.pekerja_nama,
                unitOrganisasiId: val?.master_unit_organisasi_id,
                unitOrganisasiNama: val?.master_unit_organisasi_nama,
                jabatanNama: val?.master_jabatan_nama,
                dendaTotal: val?.denda_total,
                periodeGajianId: val?.periode_tahun,
                tglAwal: val?.tgl_bulan_awal,
                tglAkhir: val?.tgl_bulan_akhir,
                hasPosted: val?.has_posted
            })) ?? [],
    })
};
