import {
    DendaKeterlambatanList,
    DendaKeterlambatanCreate,
    DendaKeterlambatanPostList
} from "pages/TimeAttendance";

export const DendaKeterlambatan = {
    component: <DendaKeterlambatanList />,
    link: "denda-keterlambatan",
    name: "Denda Keterlambatan",
    access: "PEKERJA_DENDA_KETERLAMBATAN",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <DendaKeterlambatanCreate />
        },
        {
            name: "List Pekerja",
            link: "/post-list",
            component: <DendaKeterlambatanPostList />
        }
    ]
};
