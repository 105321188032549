import { dateConvert } from "components";

const date = dateConvert();

export const formInitialValues = (data = {}, filter) => ({
  dataFormSetup: {
    periodeGajianId: data?.data?.info?.periode_tahun ?? filter?.periodeGajianId,
    periodePerencanaan: data?.data?.info?.periode_tahun ?? new Date().getFullYear(),
    agamaId: filter?.agamaId,
    agamaNama: filter?.agamaNama,
    bulan: filter?.bulan,
    tglAwal: filter?.tglAwal,
    tglAkhir: filter?.tglAkhir,
    tglSetup: filter?.tglSetup ?? new Date(),
    statusPekerja: data?.data?.info?.statusPekerja ?? filter?.statusPekerja,
    unitOrganisasiId: filter?.unitOrganisasiId ?? "",
    unitOrganisasiNama: filter?.unitOrganisasiNama ?? "",
    persentase: false,
    detail:
      data?.data?.map((val) => ({
        checked: false,
        periodeGajianId: val?.periode_tahun ?? "",
        tglSetup: filter?.tglSetup ?? date.getDashYMD(new Date()),
        tglAwal: filter?.tglAwal,
        tglAkhir: filter?.tglAkhir,
        nik: val?.nip ?? "",
        pekerjaId: val?.pekerja_id ?? "",
        pekerjaNama: val?.pekerja_nama ?? "",
        unitOrganisasiId: val?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
        jabatanId: val?.master_jabatan_id ?? "",
        jabatanNama: val?.master_jabatan_nama ?? "",
        agamaId: val?.master_agama_id ?? "",
        agamaNama: val?.agama ?? "",
        upahPokok: val?.upah_pokok ?? "",
        dendaKeterlambatan: val?.denda_keterlambatan ?? "",
        finalUpahPokok: val?.final_upah_pokok ?? "",
        hasPosted: val?.has_post
      })).sort((a, b) => { return (a.hasPosted === b.hasPosted) ? 0 : a.hasPosted ? 1 : -1 }) ?? []
  },

  detailData: {
    id: data?.pekerja_upah_pokok_bulanan_id ?? "",
    komponenPayroll: data?.komponen_payroll ?? "",
    periodeGajianId: data?.periode_tahun ?? "",
    periodePerencanaan: data?.periode_tahun ?? "",
    tglSetup: data?.tgl_setup ?? "",
    nik: data?.nip ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",

    upahPokok: data?.upah_pokok ?? "",
    dendaKeterlambatan: data?.denda_keterlambatan ?? "",

    finalUpahPokok: data?.final_upah_pokok ?? ""
  }
});
