export const formSubmitMapper = {
  objWithArray: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    uang_perkubik: data?.uangPerkubik,
    total_uang_kubikasi: data?.totalUangKubikasi,
    detail: data?.detail?.map((val) => ({
      tgl_presensi: val?.tglPresensi,
      jam_kerja: val?.jamKerjaMenit,

      volume: val?.volume,
      uang_kubikasi: val?.uangKubikasi
    }))
  }),
  object: (data) => ({
    periode_tahun: data?.periodeGajianId,
    // tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    total_uang_kubikasi: data?.nominal
  }),

  quickPostData: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    tgl_setup: data?.tglSetup
  })
};
