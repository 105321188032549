import {
  ButtonCreate, ButtonDelete, DatePicker,
  InfoItemVertical,
  Input, InputFileSingle, ModalCreate, Select, TableList, InputImageSingle, ImageViewerSingle
} from "components";
import { Form, Formik, useFormikContext } from "formik";
import { useModalConfirm, useUploads } from "hooks";
import { useState } from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { dateConvert, errorFetchingSelectMapper, errorSubmitMapper, getFileLink } from "utilities";
import PekerjaApi from "../__PekerjaApi__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";

const DetailProfile = () => {
  const { values } = useFormikContext();
  const date = dateConvert();

  return (
    <>
      <Row>
        <Col>
          <InfoItemVertical label="Tempat Lahir" text={values.tempatLahir} />
          <InfoItemVertical
            label="Tgl. Lahir"
            text={values.tglLahir ? date.getDetailFull(new Date(values.tglLahir)) : ""}
          />
          <InfoItemVertical label="Jenis Kelamin" text={values.jenisKelaminNama} />
          <InfoItemVertical label="Agama" text={values.agamaNama} />
          <InfoItemVertical label="Status Kawin" text={values.statusPerkawinanNama} />
        </Col>
        <Col>
          <InfoItemVertical label="Foto Profile" text={<ImageViewerSingle link={values.fotoProfileFull} />} />
        </Col>
      </Row>

      <Card className="my-3">
        <Card.Header>Data Identitas</Card.Header>
        <Card.Body>
          <TableList
            key={values?.identitas?.length}
            data={values?.identitas}
            // loading={values?.identitas.isFetching}
            tableHeader={[
              {
                text: "Jenis Identitas"
              },
              {
                text: "No Identitas"
              },
              {
                text: "File Identitas"
              }
            ]}
            tableBody={[
              {
                field: "jenisIdentitasNama"
              },
              {
                field: "noIdentitas"
              },
              {
                customField: (val) => (
                  <a href={val.fileIdentitasFull} target="_blank" rel="noreferrer">
                    <b>Lihat File</b>
                  </a>
                ),
                props: { textCenter: true }
              }
            ]}
          />
        </Card.Body>
      </Card>
    </>
  );
};

const FormModalIdentitas = () => {
  const modalConfirm = useModalConfirm();
  const { values, handleChange, errors, touched, setValues } = useFormikContext();
  const getJenisIdentitas = useQuery(["jenisIdentitas", "dropdown"], () => PekerjaApi.getJenisIdentitas());
  const uploadFileIdentitas = useUploads(PekerjaApi.linkUploadIdentitas, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({ ...values, fileIdentitas: link, fileIdentitasFull: getFileLink({ link: link, temporary: true }) });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload file identitas gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  return (
    <>
      <Select
        label="Jenis Identitas"
        placeholder="Pilih jenis identitas"
        name="jenisIdentitasId"
        loading={getJenisIdentitas.isLoading}
        options={getJenisIdentitas?.data ?? []}
        defaultValue={getJenisIdentitas?.data?.find(
          (item) => parseInt(item.value) === parseInt(values.jenisIdentitasId)
        )}
        onChange={(val) => {
          setValues({ ...values, jenisIdentitasId: val.value, jenisIdentitasNama: val.label });
        }}
        error={Boolean(errors.jenisIdentitasId && touched.jenisIdentitasId)}
        errorText={Boolean(errors.jenisIdentitasId && touched.jenisIdentitasId) && errors.jenisIdentitasId}
        errorFetch={getJenisIdentitas.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisIdentitas.error)}
      />
      <Input
        label="No. Identitas"
        name="noIdentitas"
        type="text"
        placeholder="Masukan nomor identitas"
        value={values?.noIdentitas}
        onChange={handleChange}
        error={Boolean(errors.noIdentitas && touched.noIdentitas)}
        errorText={Boolean(errors.noIdentitas && touched.noIdentitas) && errors.noIdentitas}
      />
      <InputFileSingle
        label="Upload Dokumen Identitas"
        onClick={(e) => (uploadFileIdentitas.isLoading ? e.preventDefault() : {})}
        disabled={uploadFileIdentitas.isLoading}
        link={values.fileIdentitasFull}
        preview={Boolean(values.fileIdentitasFull)}
        onChange={(val) => {
          let formData = new FormData();
          formData.append("file", val.data);
          uploadFileIdentitas.mutateAsync(formData);
        }}
        acceptFileType={["pdf", "png", "jpg"]}
        error={Boolean(errors.fileIdentitas && touched.fileIdentitas)}
        errorText={Boolean(errors.fileIdentitas && touched.fileIdentitas) && errors.fileIdentitas}
      />
      {uploadFileIdentitas.isLoading && (
        <ProgressBar now={uploadFileIdentitas.progress} label={`Mengunggah file ${uploadFileIdentitas.progress}%`} />
      )}
    </>
  );
};

const FormProfile = ({ action }) => {
  const parentFormik = useFormikContext();
  const modalConfirm = useModalConfirm();
  const { values, errors, touched, handleChange, setFieldValue, setValues } = useFormikContext();

  const getJenisKelamin = useQuery(["jenisKelamin", "dropdown"], () => PekerjaApi.getJenisKelamin());
  const getAgama = useQuery(["agama", "dropdown"], () => PekerjaApi.getAgama());
  const getStatusKawin = useQuery(["statusKawin", "dropdown"], () => PekerjaApi.getStatusKawin());

  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const uploadProfile = useUploads(PekerjaApi.linkUploadProfil, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({ ...values, fotoProfile: link, fotoProfileFull: getFileLink({ link: link, temporary: true }) });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload Foto Gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  const formIdentitasInitialValues = {
    noIdentitas: modal?.data?.noIdentitas,
    jenisIdentitasNama: modal?.data?.jenisIdentitasNama,
    jenisIdentitasId: modal?.data?.jenisIdentitasId,
    fileIdentitas: modal?.data?.fileIdentitas,
    fileIdentitasFull: modal?.data?.fileIdentitasFull
  };

  const createDataHandler = (values, { resetForm }) => {
    let newData = [{ ...values }, ...parentFormik.values.identitas];

    parentFormik.setFieldValue("identitas", newData);
    resetForm();
    closeModalHandler();
  };

  // const updateDataHandler = (values, { resetForm }) => {
  //   let newData = [...parentFormik.values.identitas];

  //   newData.splice(modal.index, 1, values);
  //   parentFormik.setFieldValue("identitas", newData);
  //   resetForm();
  //   closeModalHandler();
  // };

  const deleteDataHandler = (index) => {
    let newData = [...parentFormik.values.identitas];

    newData.splice(index, 1);
    parentFormik.setFieldValue("identitas", newData);
  };

  const closeModalHandler = () => setModal({ show: false, action: "", data: {}, index: "" });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onButtonDeleteClick = (val, index) =>
    modalConfirm.trigger({
      type: "delete",
      data: [
        { label: "Jenis Identitas", text: val.jenisIdentitasNama },
        { label: "Nomer Identitas", text: val.noIdentitas }
      ],
      onSubmit: () => {
        deleteDataHandler(index);
        modalConfirm.close();
      },
      onHide: modalConfirm.close
    });

  const TABLE_HEADER = [
    {
      text: "Jenis Identitas"
    },
    {
      text: "No Identitas"
    },
    {
      text: "File Identitas"
    },
    {
      text: "Aksi",
      props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
    }
  ];
  const TABLE_BODY = [
    {
      field: "jenisIdentitasNama"
    },
    {
      field: "noIdentitas"
    },
    {
      customField: (val) => (
        <a href={val.fileIdentitasFull} target="_blank" rel="noreferrer">
          <b>Lihat File</b>
        </a>
      ),
      props: { textCenter: true }
    },
    {
      props: { fixed: true, className: action === "READ" ? "d-none" : "text-center" },
      customField: (data, index) => (
        <>
          <ButtonDelete icon noText onClick={() => onButtonDeleteClick(data, index)} />
        </>
      )
    }
  ];

  return (
    <>
      <Row>
        <Col lg="6">
          <Row>
            <Col lg="6">
              <Input
                label="Tempat Lahir"
                type="text"
                name="tempatLahir"
                placeholder="Masukan tempat lahir"
                value={values?.tempatLahir}
                disabled={action === "READ"}
                onChange={handleChange}
                error={Boolean(errors.tempatLahir && touched.tempatLahir)}
                errorText={Boolean(errors.tempatLahir && touched.tempatLahir) && errors.tempatLahir}
              />
            </Col>
            <Col lg="6">
              <DatePicker
                label="Tgl. Lahir"
                placeholderText="Pilih tgl. lahir"
                selected={values?.tglLahir ? new Date(values.tglLahir) : ""}
                onChange={(date) => setFieldValue("tglLahir", date)}
                error={Boolean(errors.tglLahir && touched.tglLahir)}
                errorText={Boolean(errors.tglLahir && touched.tglLahir) && errors.tglLahir}
              />
            </Col>
          </Row>
          <Select
            label="Jenis Kelamin"
            placeholder="Pilih jenis kelamin"
            name="jenisKelaminId"
            loading={getJenisKelamin.isLoading}
            options={getJenisKelamin?.data ?? []}
            defaultValue={getJenisKelamin?.data?.find((item) => item.value === values.jenisKelaminId)}
            onChange={(val) => {
              setValues({ ...values, jenisKelaminId: val.value, jenisKelaminNama: val.label });
            }}
            error={Boolean(errors.jenisKelaminId && touched.jenisKelaminId)}
            errorText={Boolean(errors.jenisKelaminId && touched.jenisKelaminId) && errors.jenisKelaminId}
            errorFetch={getJenisKelamin.isError}
            errorFetchText={errorFetchingSelectMapper(getJenisKelamin.error)}
          />
          <Select
            label="Agama"
            placeholder="Pilih agama"
            name="agamaId"
            loading={getAgama.isLoading}
            options={getAgama?.data ?? []}
            defaultValue={getAgama?.data?.find((item) => item.value === values.agamaId)}
            onChange={(val) => {
              setValues({ ...values, agamaId: val.value, agamaNama: val.label });
            }}
            error={Boolean(errors.agamaId && touched.agamaId)}
            errorText={Boolean(errors.agamaId && touched.agamaId) && errors.agamaId}
            errorFetch={getAgama.isError}
            errorFetchText={errorFetchingSelectMapper(getAgama.error)}
          />
          <Select
            label="Status Kawin"
            placeholder="Pilih status kawin"
            name="statusPerkawinanId"
            loading={getStatusKawin.isLoading}
            options={getStatusKawin?.data ?? []}
            defaultValue={getStatusKawin?.data?.find((item) => item.value === values.statusPerkawinanId)}
            onChange={(val) => {
              setValues({ ...values, statusPerkawinanId: val.value, statusPerkawinanNama: val.label });
            }}
            error={Boolean(errors.statusPerkawinanId && touched.statusPerkawinanId)}
            errorText={Boolean(errors.statusPerkawinanId && touched.statusPerkawinanId) && errors.statusPerkawinanId}
            errorFetch={getStatusKawin.isError}
            errorFetchText={errorFetchingSelectMapper(getStatusKawin.error)}
          />
        </Col>
        <Col lg="6">
          <InputImageSingle
            label="Foto Profil"
            onClick={(e) => (uploadProfile.isLoading ? e.preventDefault() : {})}
            disabled={uploadProfile.isLoading}
            link={values.fotoProfileFull}
            preview={Boolean(values.fotoProfileFull)}
            onChange={(val) => {
              const maxSize = 2 * 1024 * 1024; // 2MB
              if (val.data.size > maxSize) {
                modalConfirm.infoError({
                  customTextInfoTitle: "Data gagal diupload.",
                  customTextInfoDetail: "Ukuran file melebihi batas maksimum (2MB). Silakan pilih file yang lebih kecil.",
                  onHide: modalConfirm.close,
                  onCancel: modalConfirm.close
                });
              } else {
                let formData = new FormData();
                formData.append("file", val.data);
                uploadProfile.mutateAsync(formData);
              }
            }}
            error={Boolean(errors.fotoProfil && touched.fotoProfil)}
            errorText={Boolean(errors.fotoProfil && touched.fotoProfil) && errors.fotoProfil}
          />
          {uploadProfile.isLoading && (
            <ProgressBar now={uploadProfile.progress} label={`Mengunggah file ${uploadProfile.progress}%`} />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="my-3">
            <Card.Header>Data Identitas</Card.Header>
            <Card.Body>
              {!Boolean(action === "READ") && (
                <div className="text-end mb-4">
                  <ButtonCreate tooltip={false} onClick={() => onCreateButtonClickHandler()} />
                </div>
              )}

              <TableList
                key={values?.identitas?.length}
                data={parentFormik.values?.identitas}
                // loading={values?.identitas.isFetching}
                tableHeader={TABLE_HEADER}
                tableBody={TABLE_BODY}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
      <Formik
        initialValues={formIdentitasInitialValues}
        validationSchema={formValidationSchema.identitas}
        onSubmit={createDataHandler}
      >
        {({ handleSubmit, resetForm }) => (
          <Form>
            <ModalCreate
              title="Tambah Identitas"
              show={Boolean(modal.show && modal.action === "CREATE")}
              onHide={() => {
                closeModalHandler();
                resetForm();
              }}
              onSubmit={handleSubmit}
            >
              <FormModalIdentitas />
            </ModalCreate>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const TabProfile = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateProfile = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  //untuk halaman akun pekerja yang sedang login
  const updateProfileSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "custom",
      size: "lg",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateProfileSelf.mutate(formSubmitMapper.profile(values));
        } else {
          updateProfile.mutate(formSubmitMapper.profile(values));
        }
      },
      component: (
        <Formik initialValues={values}>
          <DetailProfile />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues.profile(parentFormik.values)}
      validationSchema={formValidationSchema.profile}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <form>
            {action === "READ" ? <DetailProfile /> : <FormProfile />}

            {!Boolean(action === "READ") && (
              <div className="text-end">
                <ButtonCreate
                  tooltip={false}
                  text="Simpan"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
