import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { Image } from "react-bootstrap";

export const ImageViewerSingle = ({ link, previewHeight, previewWidth }) => {
  const [isHover, setIsHover] = useState(false);
  const [viewImage, setViewImage] = useState(false);

  return (
    <>
      <Image
        rounded
        src={link}
        height={previewHeight ? previewHeight : ""}
        width={previewWidth ? previewWidth : 150}
        className={`${isHover ? "shadow" : "shadow-sm"} border`}
        style={{
          objectFit: "cover",
          transform: isHover ? "scale(1.03)" : "none",
          transition: "all 0.1s ease-in-out",
          cursor: "zoom-in"
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => setViewImage(true)}
      />
      {viewImage && (
        <div style={{ zIndex: 1000 }}>
          <ImageViewer
            closeOnClickOutside
            disableScroll
            src={link ? [link] : []}
            currentIndex={0}
            onClose={() => setViewImage(false)}
            backgroundStyle={{ zIndex: 10000 }}
          />
        </div>
      )}
    </>
  );
};
