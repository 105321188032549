export const formSubmitValueMapper = (data) => ({
  tgl_lembur: data?.tglLembur,
  master_unit_organisasi_id: data?.unitOrganisasiId,
  nomor: data?.nomor,
  kompensasi_lembur: data?.kompensasiLembur,
  detail: data?.detail?.map((val) => ({
    pekerja_id: val.pekerjaId,
    tugas: val?.tugasLembur,
    jam_in: val?.jamIn,
    jam_out: val?.jamOut,
    bukti: val?.bukti
  }))
});
