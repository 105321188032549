import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea, rupiahConvert, InputCurrency } from "components";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();
    const rupiah = rupiahConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Periode Tahun" text={values.periodeTahun} />
                <InfoItemVertical label="Nominal" text={rupiah.getWithComa(values.nominal)} />
            </>
        );
    }

    return (
        <>
            <Input
                label="Periode Tahun"
                name="periodeTahun"
                placeholder="Masukkan periode"
                value={values.periodeTahun}
                onChange={handleChange}
                error={Boolean(errors.periodeTahun && touched.periodeTahun)}
                errorText={Boolean(errors.periodeTahun && touched.periodeTahun) && errors.periodeTahun}
            />
            <InputCurrency
                label="Nominal"
                name="nominal"
                placeholder="Masukkan Nominal"
                value={rupiah.getWithComa(values.nominal)}
                onChange={(val) => setValues({
                    ...values,
                    nominal: val
                })}
                error={Boolean(errors.nominal && touched.nominal)}
                errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
            />
        </>
    );
};
