export const formSubmitMapper = {
  objectWithArray: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    nip: data?.nip,

    master_unit_organisasi_id: data?.unitOrganisasiId,

    total_upah: data?.totalUpah,
    detail: data?.detail?.map((val) => ({
      tgl_pengiriman: val?.tglPengiriman,
      urutan_ritase: val?.urutanRitase,

      no_doket: val?.noDoket,
      customer: val?.customer,
      proyek: val?.proyek,
      jenis_ritase_kode: val?.jenisRitaseKode,
      jarak: val?.jarak,
      upah: val?.upah
    }))
  }),
  quickPostData: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    tgl_setup: data?.tglSetup
  })
};
