import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, DataStatus } from "components";
import { Content } from "./__TunjanganUangLemburKubikasiComps__";
import { formInitialValues } from "./__TunjanganUangLemburKubikasiUtils__";
import TunjanganUangLemburKubikasiApi from "./__TunjanganUangLemburKubikasiApi__";

export const TunjanganUangLemburKubikasiDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const getDetail = useQuery(["detail", "tunjangan-uang-lembur-kubikasi", id], () =>
    TunjanganUangLemburKubikasiApi.getDetail(id)
  );

  return (
    <>
      <ContentLayout showBreadcrumb>
        {getDetail?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="text-end mb-3">
              <ButtonBack
                tooltip={false}
                onClick={() => navigate("/payroll/transaksi/tunjangan-uang-lembur-kubikasi")}
              />
            </div>
            <Formik enableReinitialize initialValues={formInitialValues.dataFormSetup(getDetail?.data)}>
              <Content action="READ" loading={getDetail.isFetching} />
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
