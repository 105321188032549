import {
  IoPeopleOutline,
  IoBusinessOutline,
  IoBarChartOutline,
  IoCashOutline,
  IoPersonCircleOutline,
  IoTimeOutline
} from "react-icons/io5";
import { PiGearLight } from "react-icons/pi";
import {
  AccountPage,
  // Dashboard
  Dashboard
} from "./__PagesConfig__";
import {
  // Master
  HirarkiUnitOrganisasi,
  UnitOrganisasi,
  Grade,
  Pangkat,
  Jabatan,
  JenisKompetensi,
  JenjangPendidikan,
  KelompokJabatan,
  Kompetensi,
  KondisiKerja,
  SertifikatPelatihan,
  Step,
  UraianJabatan,
  CompensableFactor,
  TingkatCompensableFactor,

  // Transaksi
  EvaluasiJabatan,
  BaganStruktur
} from "./__PagesConfig__/Organisasi";
import {
  // Master
  Agama,
  JenisKelamin,
  JenisKontak,
  StatusPerkawinan,
  LokasiKerja,
  JenisPekerja,
  StatusPekerja,
  Bank,
  DetailStatusPekerja,
  HubunganKeluarga,
  JenisIdentitas,

  // Transaksi
  Pekerja,
} from "./__PagesConfig__/Pekerja";
import {
  // Master
  KPI,
  PerspektifKPI,
  SasaranKPI,
  SatuanKPI,

  // Transaksi
  UraianKPI,

} from "./__PagesConfig__/Kinerja";
import {
  // Master
  JobStream,
  JenisProfesi,

} from "./__PagesConfig__/TalentaKompetensi";

import {
  // Master
  TarifPPh,
  SetupPtkp,
  JenisRitase,
  UpahRitase,
  JenisOverhead,
  MappingOverhead,
  Customer,
  Proyek,
  TerStatusPtkp,
  TerPersenTarif,

  //Transaksi
  SetupKomponenJaminanHariTuaBulanan,
  SetupKomponenJaminanPensiunBulanan,
  SetupJenisUpahMinimum,
  SetupNominalUpahMinimumBPJS,
  KomponenBPJS,
  SetupKomponenJaminanKematianBulanan,
  SetupKomponenJaminanKehilanganPekerjaanBulanan,
  SetupJaminanKecelakaanKerjaBulanan,
  SetupJaminanBpjs,
  SetupKomponenJaminanKesehatanBulanan,
  KoreksiPekerja,
  SlipGaji,
  StatusPajakPekerja,
  PerhitunganPajakBulanan,
  CreateGaji,
  TunjanganHariRaya,
  GajiKe13,
  SetupUpahTetapBpjs,
  SetupUangLemburPerjam,
  SetupUangLemburKubikasi,
  UploadPengirimanBeton,
  TunjanganUangMakan,
  TunjanganUangLemburPerjam,
  TunjanganUangLemburKubikasi,
  TunjanganTetapTahunan,
  TunjanganTetapBulanan,
  TunjanganRitase,
  TunjanganUangMakanLembur,
  SetupUpahPokokTahunan,
  SetupUpahPokokBulanan,
  UangHarian,
  TunjanganUpahHarian,
  InputTimeSheetConcretePump,
  UpahRitaseCp,
  TunjanganUpahRitaseBulanan,
  Pph21Bulanan,
  Pph21Tahunan,
  SetupUangMakanTahunan,


  //Laporan
  LaporanTransferGaji,
  LaporanPajakBulanan,
  LaporanPajakTahunan,

  // LAPORAN
  RekapPenghasilanPekerja,
  PayrollUnitOrganisasi,
  PayrollOverhead,
  LaporanThr,
  LaporanGaji13,
} from "./__PagesConfig__/Payroll";

import {
  // Master  
  JenisCuti,
  AtasanUnitOrganisasi,
  JenisIjin,
  UmurPensiun,
  AlasanPHK,
  MasaKerjaPHK,

  // Transaksi
  SuratPerintahLembur,
  PermohonanIzin,
  PermohonanCuti,
  SuratPerjalananDinasKeluar,
  SuratPenugasanKhusus,
  PengajuanPekerjaPensiun,
  Phk,
  PerhitunganUangPhk,
  RealisasiPekerjaPensiun,

} from "./__PagesConfig__/OprasionalSdm";

import {
  //Master
  Shift,
  MesinAbsen,
  JadwalRegular,
  PekerjaShiftNonRegular,

  //Transaksi
  SetupJadwal,
  Presensi,
  DendaKeterlambatan,
  SetupNominalDendaKeterlambatan

} from "./__PagesConfig__/TimeAttendance";

// import { SetupHakAkses } from "./__PagesConfig__/HakAkses";
import { UploadDataPayrollTahunan, UploadDataPekerja } from "./__PagesConfig__/Utility"

const pagesConfig = [
  Dashboard,
  AccountPage,

  {
    link: "organisasi",
    name: "Organisasi",
    icon: <IoBusinessOutline />,
    subMenu: [
      {
        link: "master",
        name: "Master",
        subMenu: [
          HirarkiUnitOrganisasi,
          UnitOrganisasi,
          Grade,
          Pangkat,
          JobStream,
          JenisProfesi,
          KelompokJabatan,
          Jabatan,
          JenisKompetensi,
          Kompetensi,
          JenjangPendidikan,
          SertifikatPelatihan,
          // SertifikatProfesi,
          KondisiKerja,
          Step,
          UraianJabatan,
          CompensableFactor,
          TingkatCompensableFactor
        ]
      },
      {
        link: "transaksi",
        name: "Transaksi",
        subMenu: [EvaluasiJabatan, BaganStruktur]
      }
    ]
  },
  {
    name: "Pekerja",
    link: "pekerja",
    icon: <IoPeopleOutline />,
    subMenu: [
      {
        name: "Master",
        link: "master",
        subMenu: [
          JenisKelamin,
          Agama,
          StatusPerkawinan,
          JenisPekerja,
          LokasiKerja,
          JenisKontak,
          Bank,
          HubunganKeluarga,
          JenisIdentitas,
          StatusPekerja,
          DetailStatusPekerja
        ]
      },
      {
        name: "Transaksi",
        link: "transaksi",
        subMenu: [Pekerja]
      }
    ]
  },
  {
    name: "Kinerja",
    link: "kinerja",
    icon: <IoBarChartOutline />,
    subMenu: [
      {
        name: "Master",
        link: "master",
        subMenu: [
          KPI,
          SatuanKPI,
          PerspektifKPI,
          SasaranKPI,
        ]
      },
      {
        name: "Transaksi",
        link: "transaksi",
        subMenu: [
          UraianKPI
        ]
      }
    ]
  },
  {
    name: "Payroll",
    link: "payroll",
    icon: <IoCashOutline />,
    subMenu: [
      {
        name: "Master",
        link: "master",
        subMenu: [
          SetupJenisUpahMinimum,
          SetupNominalUpahMinimumBPJS,
          KomponenBPJS,
          SetupPtkp,
          TarifPPh,
          JenisRitase,
          UpahRitase,
          JenisOverhead,
          MappingOverhead,
          Customer,
          Proyek,
          TerStatusPtkp,
          TerPersenTarif
        ]
      },
      {
        name: "Transaksi",
        link: "transaksi",
        subMenu: [
          SetupUpahPokokTahunan,
          SetupUpahPokokBulanan,
          TunjanganTetapTahunan,
          TunjanganTetapBulanan,
          UangHarian,
          TunjanganUpahHarian,
          TunjanganHariRaya,
          GajiKe13,
          SetupUpahTetapBpjs,
          SetupJaminanBpjs,
          SetupKomponenJaminanKesehatanBulanan,
          SetupKomponenJaminanHariTuaBulanan,
          SetupKomponenJaminanPensiunBulanan,
          SetupKomponenJaminanKehilanganPekerjaanBulanan,
          SetupJaminanKecelakaanKerjaBulanan,
          SetupKomponenJaminanKematianBulanan,
          SetupUangMakanTahunan,
          TunjanganUangMakan,
          SetupUangLemburPerjam,
          TunjanganUangLemburPerjam,
          SetupUangLemburKubikasi,
          UploadPengirimanBeton,
          TunjanganUangLemburKubikasi,
          TunjanganUangMakanLembur,
          TunjanganRitase,
          InputTimeSheetConcretePump,
          UpahRitaseCp,
          TunjanganUpahRitaseBulanan,
          StatusPajakPekerja,
          // PerhitunganPajakBulanan,
          Pph21Bulanan,
          Pph21Tahunan,
          KoreksiPekerja,
          CreateGaji,
          SlipGaji,
        ]
      },
      {
        name: "Laporan",
        link: "laporan",
        subMenu: [
          LaporanTransferGaji,
          RekapPenghasilanPekerja,
          PayrollUnitOrganisasi,
          LaporanPajakBulanan,
          LaporanPajakTahunan,
          PayrollOverhead,
          LaporanThr,
          LaporanGaji13
        ]
      }
    ]
  },
  {
    name: "Operasional SDM",
    link: "operasional-sdm",
    icon: <IoPersonCircleOutline />,
    subMenu: [
      {
        name: "Master",
        link: "master",
        subMenu: [
          JenisCuti,
          UmurPensiun,
          MasaKerjaPHK,
          AlasanPHK,
          JenisIjin,
          AtasanUnitOrganisasi
        ]
      },

      {
        name: "Transaksi",
        link: "transaksi",
        subMenu: [
          SuratPerintahLembur,
          PermohonanIzin,
          PermohonanCuti,
          SuratPerjalananDinasKeluar,
          SuratPenugasanKhusus,
          PengajuanPekerjaPensiun,
          RealisasiPekerjaPensiun,
          Phk,
          PerhitunganUangPhk
        ]
      },
    ]
  },
  {
    name: "Time & Attendance",
    link: "time-attendance",
    icon: <IoTimeOutline />,
    subMenu: [
      {
        name: "Master",
        link: "master",
        subMenu: [
          Shift,
          JadwalRegular,
          MesinAbsen,
          PekerjaShiftNonRegular
        ]
      },
      {
        name: "Transaksi",
        link: "transaksi",
        subMenu: [
          SetupJadwal,
          Presensi,
          SetupNominalDendaKeterlambatan,
          DendaKeterlambatan
        ]
      },
    ]
  },
  // {
  //   name: "Hak Akses",
  //   link: "hak-akses",
  //   icon: <PiGearLight />,
  //   subMenu: [SetupHakAkses]
  // },
  {
    name: "Utility",
    link: "utility",
    icon: <PiGearLight />,
    subMenu: [
      UploadDataPayrollTahunan,
      UploadDataPekerja
    ]
  }
];

export default pagesConfig;
