import { Services } from "services";

class TunjanganUangMakanLemburApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan/" + id);
    return fetch?.data?.data;
  }

  async getSetupForm(params) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan/form", { params });
    return fetch?.data?.data;
  }

  async getPostList(params) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan/list-post", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getUnitOrganisasiForList(params) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriode() {
    const fetch = await Services.get("/utility/periode-tahun?feat=pekerja-lembur-makan-bulanan");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/lembur-makan-bulanan/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/lembur-makan-bulanan", data);
  }

  createQuickPost(data) {
    return Services.post("/transaksi/lembur-makan-bulanan/quick-post", data);
  }

  update(data, id) {
    return Services.put("/transaksi/lembur-makan-bulanan/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/lembur-makan-bulanan/" + id);
  }
}

export default new TunjanganUangMakanLemburApi();
