import { ButtonCreate, useModalConfirm } from "components";
import { Formik } from "formik";
import { useUploads } from "hooks";
import { ContentLayout } from "layouts";
import { errorSubmitMapper } from "utilities";
import * as Yup from "yup";
import UploadDataPekerjaApi from "./__UploadDataPekerjaApi__";
import { Content } from "./__UploadDataPekerjaComps__";
import { formSubmitValueMapper } from "./__UploadDataPekerjaUtilities__";

export const UploadDataPekerjaCreate = () => {
    const modalConfirm = useModalConfirm();
    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError ?? []);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => formSubmitHandler(values))
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const uploadData = useUploads(UploadDataPekerjaApi.linkUploadData, {
        onSuccess: () => {
            modalConfirm.infoSuccess({
                customTextInfoTitle: "Upload data pekerja berhasil",
                onHide: modalConfirm.close
            })
        },
        onError: (err) =>
            modalConfirm.infoError({
                customTextInfoTitle: "Upload data pekerja gagal",
                ...errorSubmitMapper(err),
                onHide: modalConfirm.close
            })
    });

    const formSubmitHandler = async (values) => {
        return await modalConfirm.trigger({
            type: "create",
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();
                // console.log("create values", formSubmitValueMapper(values).create)
                uploadData.mutateAsync(formSubmitValueMapper(values).create);
                modalConfirm.close();
            }
        });
    };

    return (
        <>
            <ContentLayout showBreadcrumb>
                <Formik
                    // enableReinitialize
                    initialValues={{
                        dataImport: "",
                    }}
                    validationSchema={Yup.object().shape({
                        dataImport: Yup.string().required("File data pekerja wajib diisi"),
                    })}
                    onSubmit={(val, { resetForm }) => {
                        resetForm();
                    }}
                >
                    {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                        <>
                            <Content
                                action="CREATE"
                                uploadData={uploadData}
                            />
                            <div className="text-end mb-3 mt-3">
                                <ButtonCreate
                                    text="SIMPAN"
                                    tooltip={false}
                                    onClick={() =>
                                        preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                                    }
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </ContentLayout>
        </>
    );
};
