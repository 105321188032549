import React from "react";
import { InfoItemVertical, Tabs } from "components";
import { useFormikContext } from "formik";
import {
  TabAkun,
  TabProfile,
  TabJabatan,
  TabKepegawaian,
  TabAlamat,
  TabPendidikan,
  TabKontak,
  TabBank,
  TabKeluarga,
  TabResume
} from "../__PekerjaComps__";
import { Col } from "react-bootstrap";
import { useAuth } from "hooks";

export const Content = ({ action, refreshData, activeTab = "akun", setActiveTab }) => {
  const auth = useAuth();
  const isGeneralUser = Boolean(auth.getId() !== 0 || auth.getUsername().toLowerCase() !== "superadmin")
  const { values } = useFormikContext();
  const tabs =
    action === "CREATE"
      ? [
        {
          key: "akun",
          title: "Akun",
          component: (
            <div className="d-flex justify-content-center">
              <Col md={action === "READ" ? 12 : 6}>
                <TabAkun action={action} refreshData={refreshData} />
              </Col>
            </div>
          )
        }
      ]
      : [
        {
          key: "resume",
          title: "Resume",
          component: (
            <div className="d-flex justify-content-center">
              <Col md={action === "READ" ? 12 : 6}>
                <TabResume action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "akun",
          title: "Akun",
          component: (
            <div className="d-flex justify-content-center">
              <Col md={action === "READ" ? 12 : 6}>
                <TabAkun action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "profile",
          title: "Profile",
          component: (
            <div className="d-flex justify-content-center">
              <Col>
                <TabProfile action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "jabatan",
          title: "Jabatan",
          component: <TabJabatan action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
        },
        {
          key: "kepegawaian",
          title: "Kepegawaian",
          component: (
            <div className="d-flex justify-content-center">
              <Col>
                <TabKepegawaian action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "alamat",
          title: "Alamat",
          component: (
            <div className="d-flex justify-content-center">
              <Col md={action === "READ" ? 12 : 6}>
                <TabAlamat action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "pendidikan",
          title: "Pendidikan",
          component: (
            <div className="d-flex justify-content-center">
              <Col>
                <TabPendidikan action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "kontak",
          title: "Kontak",
          component: (
            <div className="d-flex justify-content-center">
              <Col>
                <TabKontak action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },
        {
          key: "bank",
          title: "Bank",
          component: (
            <div className="d-flex justify-content-center">
              <Col md={action === "READ" ? 12 : 6}>
                <TabBank action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        },

        {
          key: "keluarga",
          title: "Keluarga",
          component: (
            <div className="d-flex justify-content-center">
              <Col>
                <TabKeluarga action={action} refreshData={refreshData} isGeneralUser={isGeneralUser} />
              </Col>
            </div>
          )
        }
      ];

  return (
    <div>
      <div className="d-flex flex-md-row mb-4">
        <InfoItemVertical className="me-4" label="Nama" text={values?.akun?.pekerja_nama} />
        <InfoItemVertical className="me-4" label="Unit Organisasi" text={values?.kepegawaian?.master_unit_organisasi_nama} />
        <InfoItemVertical className="me-4" label="Jabatan" text={values?.jabatan?.map((item) => item.master_jabatan_nama).join(", ")} />
      </div>
      <Tabs defaultActiveKey={activeTab} tabs={tabs} onSelect={(val) => setActiveTab(val)} />
    </div>
  );
};
