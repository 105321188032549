export const formSubmitValueMapper = (data) => ({
  array: data?.detail?.filter((obj) => obj.hasPosted === false).map((val) => ({
    periode_tahun: val?.periodeGajianId,
    nip: val?.nik,
    pekerja_id: val?.pekerjaId,
    master_unit_organisasi_id: val?.unitOrganisasiId,
    final_tunjangan_tetap: val?.nominal,
    tgl_setup: val?.tglSetup,
    tgl_bulan_awal: val?.tglAwal,
    tgl_bulan_akhir: val?.tglAkhir,
    has_posted: val.hasPosted
  })),
  object: {
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    final_tunjangan_tetap: data?.nominal,
    status: data?.status
  }
});
