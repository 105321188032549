import {
    ButtonCreate, ButtonDelete, DatePicker,
    InfoItemVertical,
    Input, InputFileSingle, ModalCreate, Select, TableList, InputImageSingle, ImageViewerSingle
} from "components";
import { Form, Formik, useFormikContext } from "formik";
import { useModalConfirm, useUploads } from "hooks";
import { useState } from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { dateConvert, errorFetchingSelectMapper, errorSubmitMapper, getFileLink } from "utilities";
import PekerjaApi from "../__PekerjaApi__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";

const DetailResume = () => {
    const { values } = useFormikContext();
    const date = dateConvert();

    return (
        <>
            <Card className="my-3">
                <Card.Body>
                    <Row>
                        <Col>
                            <InfoItemVertical label="Nama" text={values.pekerjaNama} />
                            <InfoItemVertical label="Tempat Lahir" text={values.tempatLahir} />
                            <InfoItemVertical
                                label="Tgl. Lahir"
                                text={values.tglLahir ? date.getDetailFull(new Date(values.tglLahir)) : ""}
                            />
                            <InfoItemVertical label="Jenis Kelamin" text={values.jenisKelaminNama} />
                        </Col>
                        <Col>
                            <InfoItemVertical label="Agama" text={values.agamaNama} />
                            <InfoItemVertical label="Status Kawin" text={values.statusPerkawinanNama} />
                            <InfoItemVertical label="Status Kawin" text={values.alamat} />
                        </Col>
                        <Col>
                            <InfoItemVertical label="Foto Profile" text={<ImageViewerSingle link={values.fotoProfileFull} />} />
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
            <Card>
                <Card.Header>Data Kepegawaian</Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col>
                            <InfoItemVertical label="NIP" text={values.nip} />
                            <InfoItemVertical
                                label="Tgl. Bergabung"
                                text={values.tanggalBergabung ? date.getDetailFull(new Date(values.tanggalBergabung)) : ""}
                            />
                            <InfoItemVertical
                                label="Tgl. Pengangkatan"
                                text={values.tanggalPengangkatan ? date.getDetailFull(new Date(values.tanggalPengangkatan)) : ""}
                            />
                            <InfoItemVertical label="No. Pengangkatan" text={values.noPengangkatan} />
                            <InfoItemVertical
                                label="SK. Pengangkatan"
                                text={
                                    <a href={values.skPengangkatanFull} target="_blank" rel="noreferrer">
                                        Lihat File
                                    </a>
                                }
                            />
                        </Col>
                        <Col>
                            <InfoItemVertical label="Jenis Pekerja" text={values.jenisPekerjaNama} />
                            <InfoItemVertical label="Status Pekerja" text={values.statusPekerjaNama} />
                            <InfoItemVertical label="Detail Status Pekerja" text={values.detailStatusPekerjaNama} />
                            <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                            <InfoItemVertical label="Lokasi Kerja" text={values.lokasiKerjaNama} />
                        </Col>
                    </Row>
                    <TableList
                        responsive
                        data={values?.jabatan}
                        tableHeader={[
                            {
                                text: "Jenis Jabatan"
                            },
                            {
                                text: "Kode Jabatan"
                            },
                            {
                                text: "Nama Jabatan"
                            },
                            {
                                text: "Unit Organisasi"
                            },
                            {
                                text: "Kelompok Jabatan"
                            },
                            {
                                text: "Grade"
                            },
                            {
                                text: "Step"
                            },

                        ]}
                        tableBody={[
                            {
                                field: "jenisJabatanNama"
                            },
                            {
                                field: "jabatanKode"
                            },
                            {
                                field: "jabatanNama"
                            },
                            {
                                field: "unitOrganisasiNama"
                            },
                            {
                                field: "kelompokJabatanNama"
                            },
                            {
                                customField: (val) => val?.grade?.toString()
                            },
                            {
                                customField: (val) => val?.step?.toString()
                            },
                        ]}
                    />
                </Card.Body>
            </Card>

            <Row>
                <Col>
                    <Card className="my-3">
                        <Card.Header>Data Identitas</Card.Header>
                        <Card.Body>
                            <TableList
                                key={values?.identitas?.length}
                                data={values?.identitas}
                                tableHeader={[
                                    {
                                        text: "Jenis Identitas"
                                    },
                                    {
                                        text: "No Identitas"
                                    },
                                    {
                                        text: "File Identitas"
                                    }
                                ]}
                                tableBody={[
                                    {
                                        field: "jenisIdentitasNama"
                                    },
                                    {
                                        field: "noIdentitas"
                                    },
                                    {
                                        customField: (val) => (
                                            <a href={val.fileIdentitasFull} target="_blank" rel="noreferrer">
                                                <b>Lihat File</b>
                                            </a>
                                        ),
                                        props: { textCenter: true }
                                    }
                                ]}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="my-3">
                        <Card.Header>Data Kontak</Card.Header>
                        <Card.Body>
                            <TableList
                                responsive
                                data={values?.kontak}
                                tableHeader={[
                                    {
                                        text: "Jenis Kontak"
                                    },
                                    {
                                        text: "Data Kontak"
                                    },
                                    {
                                        text: "Keterangan"
                                    },

                                ]}
                                tableBody={[
                                    {
                                        field: "jenisKontakNama"
                                    },
                                    {
                                        field: "dataKontak"
                                    },
                                    {
                                        field: "keterangan"
                                    },
                                ]}
                            />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Card className="mb-3">
                <Card.Header>Alamat</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <b>Sesuai KTP</b>
                            <InfoItemVertical label="Provinsi" text={values.provinsiNama} />
                            <InfoItemVertical label="Kabupaten" text={values.kabupatenNama} />
                            <InfoItemVertical label="Kecamatan" text={values.kecamatanNama} />
                            <InfoItemVertical label="Desa" text={values.desaNama} />
                            <InfoItemVertical label="RT" text={values.rt} />
                            <InfoItemVertical label="RW" text={values.rw} />
                            <InfoItemVertical label="alamat" text={values.alamat} />
                        </Col>
                        <Col>
                            <b>Sesuai KTP</b>
                            <InfoItemVertical label="Provinsi" text={values.provinsiNama} />
                            <InfoItemVertical label="Kabupaten" text={values.kabupatenNama} />
                            <InfoItemVertical label="Kecamatan" text={values.kecamatanNama} />
                            <InfoItemVertical label="Desa" text={values.desaNama} />
                            <InfoItemVertical label="RT" text={values.rt} />
                            <InfoItemVertical label="RW" text={values.rw} />
                            <InfoItemVertical label="alamat" text={values.alamat} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export const TabResume = ({ action, refreshData, isGeneralUser }) => {
    const parentFormik = useFormikContext();

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues.resume(parentFormik?.values)}
        >
            <form>
                <DetailResume />
            </form>
        </Formik>
    );
};
