import {
    InputFileSingle,
    Select
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { dateConvert } from "utilities";

const SectionDataSetup = ({ uploadData }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const date = dateConvert();
    const currYear = date.getYear(new Date());

    const getPeriode = [
        {
            label: (currYear - 1),
            value: (currYear - 1)
        },
        {
            label: currYear,
            value: currYear
        },
        {
            label: currYear + 1,
            value: currYear + 1
        },
    ];

    return (
        <>
            <Select
                label="Periode"
                placeholder="Pilih periode"
                defaultValue={getPeriode?.find((item) => item.value === currYear)}
                options={getPeriode ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
            />
            <InputFileSingle
                label="Upload File Payroll Tahunan"
                onClick={(e) => (uploadData.isLoading ? e.preventDefault() : {})}
                disabled={uploadData.isLoading}
                onChange={(val) => {
                    let formData = new FormData();
                    formData.append("periode_tahun", values.periodeGajianId);
                    formData.append("file", val.data);

                    setValues({
                        ...values,
                        dataImport: formData
                    });
                }}
                error={Boolean(errors.dataImport && touched.dataImport)}
                errorText={Boolean(errors.dataImport && touched.dataImport) && errors.dataImport}
                acceptFileType={["xlsx"]}
            />
            {uploadData.isLoading && (
                <ProgressBar now={uploadData.progress} label={`Mengunggah file ${uploadData.progress}%`} />
            )}
        </>
    );
};

export const Content = ({ uploadData }) => {
    return (
        <>
            <Row>
                <Col md={12} className="mb-4">
                    <Card>
                        <Card.Header>
                            <b>Setup Data</b>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={8}>
                                    <SectionDataSetup uploadData={uploadData} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
