import {
    BaganStrukturList
} from "pages/Organisasi";

export const BaganStruktur = {
    name: "Bagan Struktur Organisasi",
    link: "bagan-struktur-organisasi",
    access: "BAGAN_UNIT",
    component: <BaganStrukturList />,
};
