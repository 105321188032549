import { ButtonBack, DataStatus, useModalConfirm } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import TunjanganUangLemburKubikasiApi from "./__TunjanganUangLemburKubikasiApi__";
import { Content } from "./__TunjanganUangLemburKubikasiComps__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "./__TunjanganUangLemburKubikasiUtils__";

export const TunjanganUangLemburKubikasiPostList = () => {
    const navigate = useNavigate();
    const modalConfirm = useModalConfirm();

    const defaultData = {
        periodeGajianId: new Date().getFullYear(),
        periodePerencanaan: new Date().getFullYear(),
        unitOrganisasiId: undefined,
        unitOrganisasiNama: undefined,
        pekerjaId: undefined,
        pekerjaNama: undefined,
        bulan: undefined,
        tglSetup: undefined,
        tglAwal: undefined,
        tglAkhir: undefined
    };

    const [filter, setFilter] = useState({
        active: false,
        data: defaultData
    });

    const resetFilter = () => {
        setFilter({
            active: false,
            data: defaultData
        });
    };

    const getSetupForm = useQuery(
        [
            "setup-form",
            "post-list",
            filter.data.periodeGajianId,
            filter.data?.bulan,
            filter?.data?.tglAwal,
            filter?.data?.tglAkhir,
        ],
        () =>
            TunjanganUangLemburKubikasiApi.getPostList({
                periode_tahun: filter.data.periodeGajianId,
                tgl_bulan_awal: filter?.data?.tglAwal,
                tgl_bulan_akhir: filter?.data?.tglAkhir,
                tgl_setup: filter.data.tglSetup
            }),
        {
            enabled: Boolean(
                filter.data.periodeGajianId &&
                filter.data.tglAwal &&
                filter.data.tglAkhir
            )
        }
    );

    const createData = useMutation((data) => TunjanganUangLemburKubikasiApi.createQuickPost(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            navigate("/payroll/transaksi/tunjangan-uang-lembur-kubikasi");
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError ?? []);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => formSubmitHandler(values))
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = async (values) => {
        return await modalConfirm.trigger({
            type: "create",
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();
                createData.mutateAsync(formSubmitMapper.quickPostData(values));
            }
        });
    };

    return (
        <>
            <ContentLayout showBreadcrumb>
                {getSetupForm?.isFetching ? (
                    <DataStatus loading />
                ) : (
                    <>
                        <div className="text-end mb-3">
                            <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/tunjangan-uang-lembur-kubikasi")} />
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues.formData(getSetupForm?.data, filter?.data)}
                        // validationSchema={formValidationSchema}
                        >
                            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                                <>
                                    <Content
                                        action="POST"
                                        setFilter={setFilter}
                                        filterData={filter.data}
                                        loading={getSetupForm.isFetching}
                                        onSearchButtonClick={setFilter}
                                        onResetClickButton={resetFilter}
                                    />
                                    <div className="text-end mb-3 mt-3">
                                        <Button
                                            variant="success"
                                            text="QUICK POST"
                                            disabled={Boolean(values?.detail?.length === 0)}
                                            tooltip={false}
                                            icon={false}
                                            onClick={() =>
                                                preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                                            }
                                        >
                                            <span className="mx-1 text-uppercase">
                                                QUICK POST
                                            </span>
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </>
                )}
            </ContentLayout>
        </>
    );
};
