import React from "react";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";
import { Card, } from "react-bootstrap";
import { ButtonBack, DataStatus } from "components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import BaganStrukturApi from "./__BaganStrukturApi__";
import { errorFetchingPageMapper } from "utilities";

const MyNodeComponent = ({ node }) => {
    return (
        <div className="mx-2 d-flex justify-content-center align-items-center">
            <Card>
                <div className="d-flex min-height-80" >
                    <div
                        style={{
                            width: 8,
                            background: "#115293",
                            borderRadius: "5px 0px 0px 5px",
                        }}
                    />
                    <div style={{ display: "flex", flex: 1, alignItems: "center", padding: 5 }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                alignItems: "start",
                            }}
                        >
                            <span className="text-nowrap" style={{ fontSize: "16px", fontWeight: "bold" }}>
                                {node.master_unit_organisasi_nama || "ORG"}
                            </span>
                            {node.pekerja.map((pekerja, index) => (
                                <span className="text-nowrap" style={{ fontSize: "14px", }}>{index + 1}. {pekerja.pekerja_nama}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
        // <div className="initechNode" onClick={() => alert("Hi my real name is: " + node.actor)}>{node.name}</div>
    );
};

export const BaganStrukturList = () => {
    const navigate = useNavigate();

    const getBagan = useQuery(
        ["bagan-struktur", "list"],
        () =>
            BaganStrukturApi.getList()
    );

    return (
        <div className="w-100">
            <Card>
                <Card.Header className="d-flex justify-content-between"><b>Bagan Struktur Organisasi</b> <ButtonBack tooltip={false} onClick={() => navigate(-1)} /></Card.Header>
                <Card.Body>
                    {getBagan?.isFetching || getBagan?.isError ? (
                        <DataStatus
                            loading={getBagan.isFetching}
                            text={errorFetchingPageMapper(getBagan.error)}
                        />
                    ) : (
                        <div id="initechOrgChart" className="d-flex overflow-auto" style={{ minHeight: 80 }} >
                            {getBagan?.data?.map((val, index) => (
                                <OrgChart tree={val} NodeComponent={MyNodeComponent} />
                            ))}
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};
