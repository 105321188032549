import {
  ButtonCreate, ButtonDelete, ButtonUpdate, Checkbox, CheckboxWrapper, dateConvert, DatePicker, InfoItemVertical, Input, Select, TableList, TextArea, InputFileSingle
} from "components";
import { Formik, useFormikContext } from "formik";
import { useModalConfirm, useUploads } from "hooks";
import { getFileLink, errorSubmitMapper } from "utilities";
import { useState } from "react";
import { ButtonGroup, Card, Col, Row, ProgressBar } from "react-bootstrap";
import { useQuery } from "react-query";
import * as Yup from "yup";
import SuratPerintahLemburApi from "../__SuratPerintahLemburApi__";

const ContentLembur = ({ action, onDelete, onButtonUpdateClick }) => {
  const modalConfirm = useModalConfirm();
  // const number = numberConvert();
  const { values } = useFormikContext();

  return (
    <TableList
      tableHeader={[
        {
          text: "Pekerja"
        },
        {
          text: "Tugas Lembur"
        },
        {
          text: "Jam Mulai"
        },
        {
          text: "Jam Selesai"
        },
        {
          text: "Bukti Lembur"
        },
        {
          text: "Aksi",
          props: {
            width: 50,
            fixed: true,
            style: { display: action === "READ" ? "none" : "" }
          }
        }
      ]}
      tableBody={[
        {
          field: "pekerjaNama"
        },
        {
          field: "tugasLembur"
        },
        {
          props: { textCenter: true },
          field: "jamIn"
        },
        {
          props: { textCenter: true },
          field: "jamOut"
        },
        {
          props: { textCenter: true },
          customField: (val) => (
            <>
              <a href={getFileLink({ link: val.bukti, temporary: false })} target="_blank">
                <b>Lihat File</b>
              </a>
            </>
          )
        },
        {
          props: {
            fixed: true,
            style: { display: action === "READ" && "none" }
          },
          customField: (val, index) => (
            <ButtonGroup size="sm" className="mx-1">
              {action !== "READ" && (
                <>
                  <ButtonDelete
                    icon
                    noText
                    tooltip={false}
                    onClick={() =>
                      modalConfirm.trigger({
                        type: "delete",
                        alert: true,
                        customAlertText: "Data yang terhapus tidak dapat dikembalikan",
                        onHide: modalConfirm.close,
                        onSubmit: () => {
                          modalConfirm.loading();
                          onDelete(index);
                          modalConfirm.close();
                        },
                        component: (
                          <>
                            <InfoItemVertical label="Pekerja" text={val.pekerjaNama} />
                            <InfoItemVertical label="Tugas Lembur" text={val.tugasLembur} />
                            <InfoItemVertical label="Jam Mulai" text={val.jamIn} />
                            <InfoItemVertical label="Jam Selesai" text={val.jamOut} />
                          </>
                        )
                      })
                    }
                  />
                  <ButtonUpdate
                    icon
                    noText
                    tooltip={false}
                    onClick={() => {
                      onButtonUpdateClick({ index: index, data: val, action: "UPDATE" });
                      onDelete(index);
                    }}
                  />
                </>
              )}
            </ButtonGroup>
          )
        }
      ]}
      data={values?.detail}
    />
  );
};

export const Content = ({ action }) => {
  const { values, setValues, errors, touched, setFieldValue, handleChange } = useFormikContext();
  const date = dateConvert();
  const modalConfirm = useModalConfirm();
  const [temp, setTemp] = useState({
    action: "CREATE",
    index: "",
    data: {}
  });

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => SuratPerintahLemburApi.getUnitOrganisasi());

  const getPekerja = useQuery(["pekerja", "dropdown", temp.action, values?.unitOrganisasiId, values?.tglLembur], () =>
    SuratPerintahLemburApi.getPekerja({
      master_unit_organisasi_id: values?.unitOrganisasiId,
      tgl: temp.action === "UPDATE" ? "" : values?.tglLembur
    })
  );

  const uploadBukti = useUploads(SuratPerintahLemburApi.linkBukti, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      let bukti = link;

      return bukti;
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload bukti penerimaan gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  const resetFormHandler = () => {
    setTemp({ action: "CREATE", data: {}, index: "" });
  };

  const createHandler = (obj) => {
    let newValue = [...values?.detail];

    setFieldValue("detail", [...newValue, { ...obj }]);
  };

  const updateHandler = (val) => {
    let newValue = [...values.detail];
    newValue[temp.index] = val;

    setFieldValue("detail", newValue);
    resetFormHandler();
  };

  const deleteHandler = (index) => {
    let newValue = [...values?.detail].filter((_v, i) => index !== i);

    setFieldValue("detail", newValue);
  };

  const onSubmit = (val) => {
    if (temp?.action === "CREATE") {
      createHandler(val);
    }
    if (temp?.action === "UPDATE") {
      updateHandler(val);
    }
  };

  // fungsi cek weekend
  let isWeekend = (lemburDate) => [0, 6].includes(new Date(lemburDate).getDay());

  return (
    <>
      <Row>
        <Col md={4}>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              {action === "READ" ? (
                <>
                  <InfoItemVertical label="Tanggal Lembur" text={date.getSlashDMY(new Date(values?.tglLembur))} />
                  <InfoItemVertical label="Sub Unit" text={values?.unitOrganisasiNama} />
                  <InfoItemVertical label="Nomor Surat Perintah Lembur" text={values?.nomor} />
                  <InfoItemVertical
                    label="Uang Kompensasi Lembur diluar hari kerja"
                    text={
                      values?.kompensasiLembur === true ? "Diberikan" : "Tidak diberikan"
                    }
                  />
                </>
              ) : (
                <>
                  <DatePicker
                    disabled={Boolean(action !== "CREATE")}
                    label="Tanggal Lembur"
                    placeholderText="Pilih tanggal lembur"
                    selected={values?.tglLembur}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tglLembur: val,
                        kompensasiLembur: isWeekend(val)
                      })
                    }
                    error={Boolean(errors.tglLembur && touched.tglLembur)}
                    errorText={Boolean(errors.tglLembur && touched.tglLembur) && errors.tglLembur}
                  />

                  <Select
                    disable={Boolean(action !== "CREATE")}
                    label="Sub Unit"
                    placeholder="Pilih sub unit organisasi"
                    defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                    options={getUnitOrganisasi?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                      })
                    }
                    loading={getUnitOrganisasi.isFetching}
                    errorFetch={getUnitOrganisasi.isError}
                    error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                    errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
                  />
                  <Input
                    name="nomor"
                    disabled={Boolean(action !== "CREATE")}
                    label="Nomor Surat Perintah Lembur"
                    placeholder="Masukkan nomor"
                    value={values?.nomor}
                    onChange={handleChange}
                    error={Boolean(errors.nomor && touched.nomor)}
                    errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
                  />
                  <CheckboxWrapper label="Uang Kompensasi">
                    <Checkbox
                      name={values?.kompensasiLembur}
                      checked={values?.kompensasiLembur === true}
                      onChange={() =>
                        values?.kompensasiLembur === true
                          ? setValues({
                            ...values,
                            kompensasiLembur: false
                          })
                          : setValues({
                            ...values,
                            kompensasiLembur: true
                          })
                      }
                    />
                  </CheckboxWrapper>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              {action !== "READ" ? (
                <Formik
                  enableReinitialize
                  initialValues={{
                    pekerjaId: temp?.action === "CREATE" ? "" : temp?.data?.pekerjaId,
                    pekerjaNama: temp?.action === "CREATE" ? "" : temp?.data?.pekerjaNama,
                    tugasLembur: temp?.action === "CREATE" ? "" : temp?.data?.tugasLembur,
                    jamIn: temp?.action === "CREATE" ? "" : temp?.data?.jamIn,
                    jamOut: temp?.action === "CREATE" ? "" : temp?.data?.jamOut,
                    bukti: temp?.action === "CREATE" ? "" : temp?.data?.bukti,
                    buktiFull: temp?.action === "CREATE" ? "" : getFileLink({ link: temp?.data?.bukti, temporary: false })
                  }}
                  validationSchema={Yup.object().shape({
                    pekerjaId: Yup.string().required("Pekerja wajib diisi"),
                    tugasLembur: Yup.string().required("Tugas lembur wajib diisi"),
                    jamIn: Yup.string().required("Jam mulai wajib diisi"),
                    jamOut: Yup.string().required("Jam selesai wajib diisi")
                  })}
                  onSubmit={(val, { resetForm }) => {
                    onSubmit(val);
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, setValues, handleSubmit }) => (
                    <>
                      <Row>
                        <Col>
                          <Select
                            label="Pekerja"
                            placeholder="Pilih pekerja"
                            defaultValue={getPekerja?.data?.find((item) => item.value === values.pekerjaId)}
                            options={getPekerja?.data ?? []}
                            onChange={(val) =>
                              setValues({
                                ...values,
                                pekerjaId: val.value,
                                pekerjaNama: val.label
                              })
                            }
                            loading={getPekerja.isFetching}
                            errorFetch={getPekerja.isError}
                            error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                            errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
                          />
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <Input
                                label="Jam Mulai"
                                type="time"
                                value={values?.jamIn}
                                onChange={(val) =>
                                  setValues({
                                    ...values,
                                    jamIn: val.target.value
                                  })
                                }
                                error={Boolean(errors.jamIn && touched.jamIn)}
                                errorText={Boolean(errors.jamIn && touched.jamIn) && errors.jamIn}
                              />
                            </Col>
                            <Col>
                              <Input
                                label="Jam Selesai"
                                type="time"
                                value={values?.jamOut}
                                onChange={(val) =>
                                  setValues({
                                    ...values,
                                    jamOut: val.target.value
                                  })
                                }
                                error={Boolean(errors.jamOut && touched.jamOut)}
                                errorText={Boolean(errors.jamOut && touched.jamOut) && errors.jamOut}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextArea
                            label="Tugas Lembur"
                            name="tugasLembur"
                            placeholder="Masukkan tugas lembur"
                            value={values.tugasLembur}
                            onChange={(val) =>
                              setValues({
                                ...values,
                                tugasLembur: val.target.value
                              })
                            }
                            error={Boolean(errors.tugasLembur && touched.tugasLembur)}
                            errorText={Boolean(errors.tugasLembur && touched.tugasLembur) && errors.tugasLembur}
                          />
                        </Col>
                        <Col>
                          <InputFileSingle
                            label="Upload File Bukti Lembur"
                            onClick={(e) => (uploadBukti.isLoading ? e.preventDefault() : {})}
                            disabled={uploadBukti.isLoading}
                            link={values?.buktiFull}
                            preview={Boolean(values?.buktiFull)}
                            onChange={(val) => {
                              let formData = new FormData();
                              formData.append("file", val.data);
                              uploadBukti.mutateAsync(formData).then((val) =>
                                (setValues({ ...values, bukti: val.data.data, buktiFull: getFileLink({ link: val.data.data, temporary: true }) }))
                              ); //setValues({ ...values, bukti: val.data.data);                              
                            }}
                            error={Boolean(errors.bukti && touched.bukti)}
                            errorText={Boolean(errors.bukti && touched.bukti) && errors.bukti}
                            acceptFileType={["png", "jpg", "jpeg", "pdf"]}
                          />
                          {uploadBukti.isLoading && (
                            <ProgressBar
                              now={uploadBukti.progress}
                              label={`Mengunggah file ${uploadBukti.progress}%`}
                            />
                          )}
                        </Col>
                      </Row>
                      <div className="text-end">
                        <ButtonCreate icon={true} tooltip={false} className="mb-3" onClick={handleSubmit} />
                      </div>
                    </>
                  )}
                </Formik>
              ) : (
                <></>
              )}
              <ContentLembur
                action={action}
                onDelete={deleteHandler}
                onButtonUpdateClick={(val) => setTemp({ data: val.data, index: val.index, action: "UPDATE" })}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
