export const formSubmitValueMapper = {
  objWithArray: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    total_lembur_makan: data?.uangLemburMakan,
    detail: data?.detail?.map((val) => ({
      tgl_presensi: val?.tglPresensi,
      nomor: val?.nomor,
      jam_lembur: val?.jamLembur,
      jam_in: val?.jamIn,
      jam_out: val?.jamOut,
      uang_lembur: val?.nominal
    }))
  }),
  object: (data) => ({
    periode_tahun: data?.periodeGajianId,
    // tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    total_lembur_makan: data?.nominal
  }),
  quickPostData: (data) => ({
    periode_tahun: data?.periodeGajianId,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    tgl_setup: data?.tglSetup
  })
};
