export const getFunction = {
  hitungLembur: ({ date1, date2 }) => {
    let start = new Date(date1);
    let end = new Date(date2);
    let hours = (end?.getTime() - start?.getTime()) / (1000 * 3600);
    let hourString = String(hours)?.split(".");
    let fix = 0;
    if (fix <= Number(hourString[0])) {
      fix = Number(hourString[0]);
    }
    return fix;
  },
  tanggalSekarang: () => {
    const date = new Date();
    let day;
    if (date.getDate() >= 10) {
      day = date?.getDate();
    } else {
      day = "0" + date?.getDate();
    }
    let month;
    if (date?.getMonth() > 9) {
      month = date?.getMonth() + 1;
    } else {
      month = "0" + (date?.getMonth() + 1);
    }
    let year = date?.getFullYear();
    return year + "-" + month + "-" + day;
  },
  errorLembur: ({ date1, date2 }) => {
    let start = new Date(date1);
    let end = new Date(date2);
    if (start?.getTime() <= end?.getTime()) {
      return false;
    } else {
      return true;
    }
  },
  hoursMinutes: ({ date }) => {
    const fullDate = new Date(date);
    const isoStandard = fullDate?.toString()?.split(" ");
    const fullTime = isoStandard[4]?.split(":");
    const hoursminutes = fullTime[0] + ":" + fullTime[1];
    return hoursminutes;
  },
  getMilisecond: (date1) => {
    const date = new Date(date1);
    return date?.getTime();
  }
};
const getDefaultDate = (timeString) => {
  const date = new Date();
  const [hours, minutes] = timeString?.split(":");
  date?.setHours(hours);
  date?.setMinutes(minutes);
  return date;
};

export const formInitialValues = (data = {}) => ({
  id: data?.master_shift_id,
  nama: data?.master_shift_nama,
  kode: data?.kode_shift ?? "",
  jamMasuk: data?.jam_masuk ? getDefaultDate(data?.jam_masuk ?? "00:00:00") : "",
  jamPulang: data?.jam_keluar ? getDefaultDate(data?.jam_keluar ?? "00:00:00") : "",
  dualShift: data?.dual_shift,
  jamMasuk1: data?.jam_masuk ? getDefaultDate(data?.jam_masuk_shift_2 ?? "00:00:00") : "",
  jamPulang1: data?.jam_keluar ? getDefaultDate(data?.jam_keluar_shift_2 ?? "00:00:00") : "",
  detailJamMasuk: data?.jam_masuk ? data?.jam_masuk : "",
  detailJamPulang: data?.jam_keluar ? data?.jam_keluar : "",
  detailJamMasuk1: data?.jam_masuk ? data?.jam_masuk_shift_2 : "",
  detailJamPulang2: data?.jam_keluar ? data?.jam_keluar_shift_2 : "",
  // scanId: data?.scan ?? "",
  // scanNama: "",
  // durasiId: data?.durasi_opsi ?? "",
  // durasiNama: "",
  // counterAs: data?.hitungan_shift ?? "",
  // shiftLemburId: data?.shift_overtime ?? "",
  // shiftLemburNama: "",
  // shiftTerpendekId: data?.shift_pendek ?? "",
  // shiftTerpendekNama: "",
  durasiMaksiman: data?.durasi_maksimal ?? "",
  jamMulaiLembur: data?.jam_lembur_mulai ? getDefaultDate(data?.jam_lembur_mulai ?? "00:00:00") : "",
  jamMulaiLemburMakan: data?.jam_mulai_lembur_makan ? getDefaultDate(data?.jam_mulai_lembur_makan ?? "00:00:00") : "",
  jamMulaiTerlambat: data?.jam_mulai_terlambat ? getDefaultDate(data?.jam_mulai_terlambat ?? "00:00:00") : "",
});
