import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { errorSubmitMapper } from "utilities";
import { ButtonBack, DataStatus, useModalConfirm, ButtonCreate } from "components";
import { Content } from "./__TunjanganTetapBulananComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__TunjanganTetapBulananUtilities__";
import TunjanganTetapBulananApi from "./__TunjanganTetapBulananApi__";

export const TunjanganTetapBulananCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  const defaultData = {
    periodeGajianId: new Date().getFullYear(),
    periodePerencanaan: new Date().getFullYear(),
    unitOrganisasiId: undefined,
    unitOrganisasiNama: undefined,
    bulan: undefined,
    tglSetup: undefined,
    tglAwal: undefined,
    tglAkhir: undefined
  };

  const [filter, setFilter] = useState({
    active: false,
    data: defaultData
  });

  const resetFilter = () => {
    setFilter({
      active: false,
      data: defaultData
    });
  };

  const getSetupForm = useQuery(
    [
      "setup-form",
      "list",
      filter.data.periodeGajianId,
      filter.data?.unitOrganisasiId,
      filter.data?.pekerjaId,
      filter.data?.bulan,
      filter?.data?.tglAwal,
      filter?.data?.tglAkhir
    ],
    () =>
      TunjanganTetapBulananApi.getSetupForm({
        periode_tahun: filter.data.periodeGajianId,
        master_unit_organisasi_id: filter?.data?.unitOrganisasiId,
        pekerja_id: filter?.data?.pekerjaId,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir,
        tgl_setup: filter.data.tglSetup
      }),
    {
      enabled: Boolean(
        filter.data.periodeGajianId &&
        // filter.data.unitOrganisasiId &&
        filter.data.tglSetup &&
        filter.data.tglAwal &&
        filter.data.tglAkhir
      )
    }
  );

  const createData = useMutation((data) => TunjanganTetapBulananApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      navigate("/payroll/transaksi/tunjangan-tetap-bulanan");
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const t = Object.values(errors)
        .flat()
        .filter((v) => v !== undefined)
        .map((v) => Object.values(v))
        .flat();

      const getError = Array.from(new Set(t));

      if (getError.length > 0) {
        reject(getError ?? []);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => formSubmitHandler(values))
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "create",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createData.mutateAsync(formSubmitValueMapper(values).array);
      }
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        {getSetupForm?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="text-end mb-3">
              <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/tunjangan-tetap-bulanan")} />
            </div>
            <Formik
              // enableReinitialize
              initialValues={formInitialValues(getSetupForm?.data, filter?.data).dataFormSetup}
              validationSchema={formValidationSchema}
            // onSubmit={(val, { resetForm }) => {
            //     filteredSearchHandler(val);
            //     // resetForm();
            // }}
            >
              {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                <>
                  <Content
                    action="CREATE"
                    loading={getSetupForm.isFetching}
                    onSearchButtonClick={setFilter}
                    onResetClickButton={resetFilter}
                  />
                  <div className="text-end mb-3 mt-3">
                    <ButtonCreate
                      text="SIMPAN"
                      disabled={Boolean(values?.detail?.length === 0)}
                      tooltip={false}
                      onClick={() =>
                        preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                      }
                    />
                  </div>
                </>
              )}
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
