import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, rupiahConvert, InputCurrency } from "components";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();
    const rupiah = rupiahConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Periode Gajian" text={values.periodeTahun} />
                <InfoItemVertical label="Uang Makan" text={values.uangMakan ? rupiah.getWithComa(values?.uangMakan) : "-"} />
            </>
        );
    }

    return (
        <>
            <Input
                label="Periode Tahun"
                name="periodeTahun"
                placeholder="Masukkan periode tahun"
                value={values.periodeTahun}
                onChange={handleChange}
                error={Boolean(errors.periodeTahun && touched.periodeTahun)}
                errorText={Boolean(errors.periodeTahun && touched.periodeTahun) && errors.periodeTahun}
            />
            <InputCurrency
                label="Uang Makan"
                name="uangMakan"
                placeholder="Masukkan uang makan"
                value={rupiah.getWithComa(values.uangMakan)}
                onChange={(val) => setValues({
                    ...values,
                    uangMakan: val
                })}
                error={Boolean(errors.uangMakan && touched.uangMakan)}
                errorText={Boolean(errors.uangMakan && touched.uangMakan) && errors.uangMakan}
            />
        </>
    );
};
