export const formInitialValues = {
  dataFormSetup: (data = {}, filter) => ({
    periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId,
    periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
    pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
    pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
    nik: data?.nip,
    bulan: filter?.bulan,
    tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
    tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
    tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
    unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
    uangPerkubik: data?.uang_perkubik,
    totalUangKubikasi: data?.total_uang_kubikasi,
    jenisPekerja: filter?.jenisPekerja,

    detail:
      data?.detail?.map((val) => ({
        tglPresensi: val?.tgl_presensi,
        jamKerjaJam: val?.jam_kerja_jam,
        jamKerjaMenit: val?.jam_kerja_menit,

        volume: val?.volume,
        uangKubikasi: val?.uang_kubikasi,

        uangPerkubik: val?.uang_perkubik
      })) ?? []
  }),

  detailData: (data = {}) => ({
    id: data?.pekerja_kubikasi_bulanan_id ?? "",
    komponenPayroll: data?.komponen_payroll ?? "",
    periodeGajianId: data?.periode_tahun ?? "",
    periodePerencanaan: data?.periode_tahun ?? "",
    tglSetup: data?.tgl_setup ?? "",
    tglAwal: data?.tgl_bulan_awal,
    tglAkhir: data?.tgl_bulan_akhir,
    nik: data?.nip ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",
    nominal: data?.total_uang_kubikasi ?? ""
  }),

  formData: (data, filter) => ({
    periodeGajianId: filter?.periodeGajianId,
    periodePerencanaan: new Date().getFullYear(),
    bulan: filter?.bulan,
    tglAwal: filter?.tglAwal ?? data?.tgl_bulan_awal ?? "",
    tglAkhir: filter?.tglAkhir ?? data?.tgl_bulan_akhir ?? "",
    tglSetup: filter?.tglSetup ?? data?.tgl_setup ?? new Date(),

    detail:
      data?.presensi?.map((val) => ({
        nik: val?.nip,
        pekerjaId: val?.pekerja_id,
        pekerjaNama: val?.pekerja_nama,
        unitOrganisasiId: val?.master_unit_organisasi_id,
        unitOrganisasiNama: val?.master_unit_organisasi_nama,
        jabatanNama: val?.master_jabatan_nama,
        uangKubikasi: val?.uang_kubikasi,
        periodeGajianId: val?.periode_tahun,
        tglAwal: val?.tgl_bulan_awal,
        tglAkhir: val?.tgl_bulan_akhir,
        hasPosted: val?.has_posted
      })) ?? [],
  })

};
