import { Checkbox, CheckboxWrapper, DatePicker, InfoItemVertical, Input, InputNumber } from "components";
import { useFormikContext } from "formik";
import { getFunction } from "../__ShiftUtils__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues, setFieldValue } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama Shift" text={values?.nama ?? "-"} />
        <InfoItemVertical label="Kode" text={values?.kode ?? "-"} />
        <InfoItemVertical
          label="Jam Masuk"
          text={values?.jamMasuk !== "" ? getFunction.hoursMinutes({ date: values?.jamMasuk }) : ""}
        />
        <InfoItemVertical
          label="Jam Pulang"
          text={values?.jamPulang !== "" ? getFunction.hoursMinutes({ date: values?.jamPulang }) : ""}
        />
        {values?.dualShift === true && (
          <>
            <InfoItemVertical
              label="Jam Masuk Shift Kedua"
              text={values?.jamMasuk !== "" ? getFunction.hoursMinutes({ date: values?.jamMasuk }) : ""}
            />
            <InfoItemVertical
              label="Jam Shift Kedua"
              text={values?.jamPulang !== "" ? getFunction.hoursMinutes({ date: values?.jamPulang }) : ""}
            />
          </>
        )}
        <InfoItemVertical label="Maksimal Durasi Kerja (jam)" text={values?.durasiMaksiman ?? "-"} />
        <InfoItemVertical
          label="Jam Mulai Lembur"
          text={values?.jamMulaiLembur !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiLembur }) : ""}
        />
        <InfoItemVertical
          label="Jam Mulai Lembur Makan"
          text={values?.jamMulaiLemburMakan !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiLemburMakan }) : ""}
        />
        <InfoItemVertical
          label="Jam Maksimal Keterlambatan"
          text={values?.jamMulaiTerlambat !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiTerlambat }) : ""}
        />
      </>
    );
  }
  if (action === "READ_CONFIRM") {
    return (
      <>
        <InfoItemVertical label="Nama Shift" text={values?.nama ?? "-"} />
        <InfoItemVertical label="Kode" text={values?.kode ?? "-"} />
        <InfoItemVertical
          label="Jam Masuk"
          text={values?.jamMasuk !== "" ? getFunction.hoursMinutes({ date: values?.jamMasuk }) : ""}
        />
        <InfoItemVertical
          label="Jam Pulang"
          text={values?.jamPulang !== "" ? getFunction.hoursMinutes({ date: values?.jamPulang }) : ""}
        />
        {values?.dualShift === true && (
          <>
            <InfoItemVertical
              label="Jam Shift Kedua"
              text={values?.jamMasuk !== "" ? getFunction.hoursMinutes({ date: values?.jamMasuk }) : ""}
            />
            <InfoItemVertical
              label="Jam Shift Kedua"
              text={values?.jamPulang !== "" ? getFunction.hoursMinutes({ date: values?.jamPulang }) : ""}
            />
          </>
        )}
        <InfoItemVertical label="Maksimal Durasi Kerja (jam)" text={values?.durasiMaksiman ?? "-"} />
        <InfoItemVertical
          label="Jam Mulai Lembur"
          text={values?.jamMulaiLembur !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiLembur }) : ""}
        />
        <InfoItemVertical
          label="Jam Mulai Lembur Makan"
          text={values?.jamMulaiLemburMakan !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiLemburMakan }) : ""}
        />
        <InfoItemVertical
          label="Jam Maksimal Keterlambatan"
          text={values?.jamMulaiTerlambat !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiTerlambat }) : ""}
        />
      </>
    );
  }
  if (action === "CREATE_CONFIRM") {
    return (
      <>
        <InfoItemVertical label="Nama Shift" text={values?.nama ?? "-"} />
        <InfoItemVertical label="Kode" text={values?.kode ?? "-"} />
        <InfoItemVertical
          label="Jam Masuk"
          text={values?.jamMasuk !== "" ? getFunction.hoursMinutes({ date: values?.jamMasuk }) : ""}
        />
        <InfoItemVertical
          label="Jam Pulang"
          text={values?.jamPulang !== "" ? getFunction.hoursMinutes({ date: values?.jamPulang }) : ""}
        />
        {values?.dualShift === true && (
          <>
            <InfoItemVertical
              label="Jam Shift Kedua"
              text={values?.jamMasuk !== "" ? getFunction.hoursMinutes({ date: values?.jamMasuk }) : ""}
            />
            <InfoItemVertical
              label="Jam Shift Kedua"
              text={values?.jamPulang !== "" ? getFunction.hoursMinutes({ date: values?.jamPulang }) : ""}
            />
          </>
        )}
        <InfoItemVertical label="Maksimal Durasi Kerja (jam)" text={values?.durasiMaksiman ?? "-"} />
        <InfoItemVertical
          label="Jam Mulai Lembur"
          text={values?.jamMulaiLembur !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiLembur }) : ""}
        />
        <InfoItemVertical
          label="Jam Mulai Lembur Makan"
          text={values?.jamMulaiLemburMakan !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiLemburMakan }) : ""}
        />
        <InfoItemVertical
          label="Jam Maksimal Keterlambatan"
          text={values?.jamMulaiTerlambat !== "" ? getFunction.hoursMinutes({ date: values?.jamMulaiTerlambat }) : ""}
        />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama Shift"
        name="nama"
        placeholder="Masukkan nama shift"
        value={values?.nama ?? ""}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <Input
        label="Kode Shift"
        name="kode"
        placeholder="Masukkan kode shift"
        value={values?.kode ?? ""}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />
      <DatePicker
        label="Jam Masuk"
        placeholderText="Masukkan Jam"
        selected={values?.jamMasuk !== null ? values?.jamMasuk : "00:00"}
        onChangeDefault={(val) => {
          setValues({
            ...values,
            jamMasuk: val ?? undefined
          });
        }}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      // error={Boolean(errors.jamMasuk && touched.jamMasuk)}
      // errorText={Boolean(errors.jamMasuk && touched.jamMasuk) && errors.jamMasuk}
      />
      <DatePicker
        label="Jam Pulang"
        placeholderText="Masukkan Jam"
        selected={values?.jamPulang !== null ? values?.jamPulang : "00:00"}
        onChangeDefault={(val) => {
          setValues({
            ...values,
            jamPulang: val ?? undefined
          });
        }}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      // filterTime={(time) => {
      //   return time > getFunction.getMilisecond(values.jamMasuk);
      // }}
      // error={Boolean(errors.jamPulang && touched.jamPulang)}
      // errorText={Boolean(errors.jamPulang && touched.jamPulang) && errors.jamPulang}
      />
      <CheckboxWrapper>
        <Checkbox
          label="Dual Shift"
          name="dualShift"
          checked={values?.dualShift}
          onChange={() => setValues({ ...values, dualShift: !values?.dualShift })}
        />
      </CheckboxWrapper>
      {values?.dualShift === true && (
        <>
          <DatePicker
            label="Jam Masuk Shift Kedua"
            placeholderText="Masukkan Jam"
            selected={values?.jamMasuk1 !== null ? values?.jamMasuk1 : "00:00"}
            onChangeDefault={(val) => {
              setValues({
                ...values,
                jamMasuk1: val ?? undefined
              });
            }}
            showTimeInput
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          // error={Boolean(errors.jamMasuk && touched.jamMasuk)}
          // errorText={Boolean(errors.jamMasuk && touched.jamMasuk) && errors.jamMasuk}
          />
          <DatePicker
            label="Jam Pulang Shift Kedua"
            placeholderText="Masukkan Jam"
            selected={values?.jamPulang1 !== null ? values?.jamPulang1 : "00:00"}
            onChangeDefault={(val) => {
              setValues({
                ...values,
                jamPulang1: val ?? undefined
              });
            }}
            showTimeInput
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          // filterTime={(time) => {
          //   return time > getFunction.getMilisecond(values.jamMasuk);
          // }}
          // error={Boolean(errors.jamPulang && touched.jamPulang)}
          // errorText={Boolean(errors.jamPulang && touched.jamPulang) && errors.jamPulang}
          />
        </>
      )}
      <InputNumber
        label="Maksimal Durasi Kerja (jam)"
        name="durasiMaksiman"
        placeholder="Masukkan durasi maksimal kerja dalam jam"
        value={values?.durasiMaksiman}
        onChange={(val) => setFieldValue("durasiMaksiman", val)}
        error={Boolean(errors.durasiMaksiman && touched.durasiMaksiman)}
        errorText={Boolean(errors.durasiMaksiman && touched.durasiMaksiman) && errors.durasiMaksiman}
      />
      <DatePicker
        label="Jam Mulai Lembur"
        placeholderText="Masukkan Jam"
        selected={values?.jamMulaiLembur !== null ? values?.jamMulaiLembur : "00:00"}
        onChangeDefault={(val) => {
          setValues({
            ...values,
            jamMulaiLembur: val ?? undefined
          });
        }}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      // filterTime={(time) => {
      //   return time > getFunction.getMilisecond(values.jamMasuk);
      // }}
      // error={Boolean(errors.jamMulaiLembur && touched.jamMulaiLembur)}
      // errorText={Boolean(errors.jamMulaiLembur && touched.jamMulaiLembur) && errors.jamMulaiLembur}
      />
      <DatePicker
        label="Jam Mulai Lembur Makan"
        placeholderText="Masukkan Jam"
        selected={values?.jamMulaiLemburMakan !== null ? values?.jamMulaiLemburMakan : "00:00"}
        onChangeDefault={(val) => {
          setValues({
            ...values,
            jamMulaiLemburMakan: val ?? undefined
          });
        }}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      // filterTime={(time) => {
      //   return time > getFunction.getMilisecond(values.jamMasuk);
      // }}
      // error={Boolean(errors.jamMulaiLembur && touched.jamMulaiLembur)}
      // errorText={Boolean(errors.jamMulaiLembur && touched.jamMulaiLembur) && errors.jamMulaiLembur}
      />
      <DatePicker
        label="Jam Maksimal Keterlambatan"
        placeholderText="Masukkan Jam"
        selected={values?.jamMulaiTerlambat !== null ? values?.jamMulaiTerlambat : "00:00"}
        onChangeDefault={(val) => {
          setValues({
            ...values,
            jamMulaiTerlambat: val ?? undefined
          });
        }}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      // filterTime={(time) => {
      //   return time > getFunction.getMilisecond(values.jamMasuk);
      // }}
      // error={Boolean(errors.jamMulaiLembur && touched.jamMulaiLembur)}
      // errorText={Boolean(errors.jamMulaiLembur && touched.jamMulaiLembur) && errors.jamMulaiLembur}
      />
    </>
  );
};
